import React from "react";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import {
  Box,
  Typography,
  withStyles,
  Grid,
  ButtonBase,
} from "@material-ui/core";
import { connect } from "react-redux";
import clsx from "clsx";

import LOGO from "../../../static/logo.jpg";
import {
  topRoutes,
  bottomRoutes,
  renderMetaAdsRoute,
} from "../Routes/ProjectRoutes";
import { renderManageRoute } from "../Routes/ProjectRoutes";

function ManageNavBarDesktop(props) {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const { classes, agent, partner } = props;
  const { type } = partner || {};

  let routesArray = [];
  let layout = "";

  if (location.pathname.includes("/meta-ads")) {
    routesArray = renderMetaAdsRoute;
    layout = "meta-ads";
  } else {
    routesArray = props.partner.isZohoEnabled
      ? renderManageRoute
      : renderManageRoute.filter((i) => i.name !== "Manage Addons");
    layout = "manage";
    routesArray = routesArray.filter(
      (route) =>
        !(
          props.user.agentRole !== "OWNER" &&
          (route.to === "/roles" || route.to === "/team" || route.to === "/sso")
        )
    );
  }

  routesArray = routesArray.filter((i) => !!i.name);

  const formatLayout = (layout) => {
    return layout
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className={classes.root}>
      <Box mx={2}>
        <Box my={2} mb={3} color="#000000">
          <Typography variant="h3">{formatLayout(layout)}</Typography>
        </Box>
        {routesArray.map((route, index) => {
          let className = "inactive";
          if (location.pathname.includes(route.to)) {
            className = "active";
          }
          return route.name && !route.excludedTypes?.includes(type) ? (
            <ButtonBase
              key={index}
              className={`${classes.linkButton} ${classes[className]}`}
              onClick={() => {
                history.push(`/${layout}` + route.to);
              }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes["icon_" + className]}
                  >
                    {route.icon}
                  </Box>
                </Grid>
                <Grid item xs>
                  <Typography
                    variant="body2"
                    align="left"
                    style={{ fontSize: 14 }}
                    className={classes["name_" + className]}
                  >
                    {route.name}
                  </Typography>
                </Grid>
              </Grid>
            </ButtonBase>
          ) : null;
        })}
      </Box>
    </div>
  );
}

const styles = (theme) => ({
  root: {
    width: "280px",
    height: "100vh",
    background: "rgb(255, 255, 255)",
    borderRight: "1px solid rgb(240,240,240)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    color: "gray",
    [theme.breakpoints.down("md")]: {
      width: "260px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  logo: {
    height: 50,
    marginTop: 15,
  },
  link_inactive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "35px",
    width: "35px",
    margin: "auto",
    borderRadius: "50%",
    cursor: "pointer",
    transition: "0.5s",
  },
  link_active: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "35px",
    width: "35px",
    margin: "auto",
    borderRadius: "50%",
    backgroundColor: "white",
    color: "white",
    cursor: "pointer",
    transition: "0.5s",
  },
  // profile_active: {

  // },
  // profile_inactive: {

  // }
  icon_active: {
    color: theme.palette.primary.main,
  },
  icon_inactive: {
    color: "rgb(20,20,20)",
  },
  name_active: {
    color: theme.palette.primary.main,
  },
  name_inactive: {
    color: "rgb(20,20,20)",
  },
  linkButton: {
    margin: "3px 0em",
    width: "100%",
    borderRadius: "5px",
    padding: "10px",
  },
  active: {
    backgroundColor: "#46148617",
  },
  inactive: {
    backgroundColor: "transparent",
  },
});

const connectedManageNavBarDesktop = connect((state) => ({
  user: state.login.user,
  agent: state.login.user,
  partner: state.partner.partner,
}))(ManageNavBarDesktop);

export default withStyles(styles)(connectedManageNavBarDesktop);
