import React, { Component } from "react";
import { withStyles, Grid, Typography, Box } from "@material-ui/core";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import ManageNavBarDesktop from "./Navbar/ManageNavBarDesktop";
import { renderMetaAdsRoute } from "./Routes/ProjectRoutes";
import { fetchPartnerDetails } from "../../store/partnerState";
import { checkAccess } from "../../helpers/RBAC";
import LockIcon from "@material-ui/icons/Lock";
class MetaAdsLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.fetchPartnerDetails();
  }

  render() {
    const { classes, agent, partner, user, partnerAgents } = this.props;
    const { type } = partner || {};
    const baseroute = "/meta-ads";
    //rbac

    return (
      <Grid container className={classes.root}>
        {true ? (
          <>
            <Grid item>
              <ManageNavBarDesktop />
            </Grid>
            <Grid item xs className={classes.routeWrapper}>
              <div id="route-container" className={classes.routeContainer}>
                <Switch>
                  <Route
                    key={30}
                    exact
                    path={baseroute}
                    render={(routeProps) => (
                      <Redirect to={`/meta-ads/project`} {...routeProps} />
                    )}
                  />
                  {renderMetaAdsRoute.map((route, index) => {
                    return route.name ? (
                      <Route
                        key={index}
                        path={baseroute + route.to}
                        component={
                          !!!route.excludedTypes?.includes(type) &&
                          route.component
                        }
                      />
                    ) : (
                      <></>
                    );
                  })}
                  <Route component={() => <Redirect to="/error" />} />
                </Switch>
              </div>
            </Grid>
          </>
        ) : (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              height: "80vh",
              width: "100%",
              alignItems: "center",
              // backgroundColor: "#f5f5f5",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <LockIcon
              style={{
                fontSize: 90,
                color: "grey",
                marginBottom: "10px",
              }}
            />
            <Typography variant="h4" color="textSecondary">
              Access Denied
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Looks like you don't have the necessary permissions to view this
              page
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Please contact your administrator for more information.
            </Typography>
          </Box>
        )}
      </Grid>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    boxSizing: "border-box",
  },
  routeWrapper: {
    height: "100vh",
    overflow: "hidden",
    boxSizing: "border-box",
    background: "white",
  },
  routeContainer: {
    width: "100%",
    height: "100vh",
    transition: "1s",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {},
  },
});

const connectedMetaAdsLayout = connect(
  (state) => ({
    user: state.login.user,
    agent: state.login.user,
    partner: state.partner.partner,
    partnerAgents: state.partnerAgents.partnerAgents,
  }),
  { fetchPartnerDetails }
)(MetaAdsLayout);

export default withStyles(styles)(connectedMetaAdsLayout);
