import React, { Component, useEffect, useState } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
  Tabs,
  Tab,
} from "@material-ui/core";
import { DateRangePicker } from "react-dates";
import { TablePagination } from "@material-ui/core";
import { URL } from "../../config/config";
import {
  NavigateNext,
  NavigateBefore,
  FiberManualRecord,
  FileCopyOutlined,
  FilterNone,
  VisibilityOutlined,
  Edit,
  Clear,
  Flag,
  GetApp,
} from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import Header from "../../commons/Header/Header";
import { connect } from "react-redux";
import { getUnavailablePlanFamilies } from "../../store/planFamilyState";
import { toHaveStyle } from "@testing-library/jest-dom";
import { fetchWccPlans } from "../../store/wccPlanState";
import axios from "axios";
import {
  addPlanFamily,
  updateWccFamily,
  updateWhatsappPaymentsPlanFamily,
  fetchPartnerDetails,
} from "../../store/partnerState";
import plansHelper from "../../helpers/plansHelper";
import { checkAccess } from "../../helpers/RBAC";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import AdsTable from "./table/AdsTable";
import withMediaQuery from "../../helpers/mediaQueryHelper";
import moment from "moment";

const MetaAds = (props) => {
  const { classes, mediaQuery } = props;
  const [tab, setTab] = useState("All");
  const [alertObj, setAlertObj] = useState({
    alertType: "",
    alertMsg: "",
  });
  const [reportLoading, setReportLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ads, setAds] = useState([]);
  const [counts, setCounts] = useState({});
  const [paginationCount, setPaginationCount] = useState(0);
  const [page, setPage] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [applied, setApplied] = useState({
    adStartDate: {
      startDate: null,
      endDate: null,
      focus: null,
    },
    adStopDate: {
      startDate: null,
      endDate: null,
      focus: null,
    },
  });
  const [assistantFilter, setAssistantFilter] = useState({});
  const location = useLocation();

  const tabs =
    page == "Project"
      ? [
          {
            display: "All",
            state: "allCount",
            // icon: <AssessmentOutlined className={classes.tabIcon} />,
            theme: "#5000bf59",
          },
          {
            display: "FB not connected",
            state: "fbNotConnectedCount",
            theme: "#00b4bf59",
          },
          {
            display: "Page not selected",
            state: "pageNotSelectedCount",
            // icon: <Done className={classes.tabIcon} />,
            theme: "#f2c14e3d",
          },
          {
            display: "WA Number not linked",
            state: "waNumberNotLinkedCount",
            // icon: <DoneAll className={classes.tabIcon} />,
            theme: "#ff000059",
          },
          {
            display: "Connected",
            state: "connectedCount",
            theme: "#08cf6533",
          },
        ]
      : [
          {
            display: "All",
            state: "all",
            // icon: <AssessmentOutlined className={classes.tabIcon} />,
            theme: "#5000bf59",
          },
          {
            display: "Live",
            state: "liveCount",
            theme: "#08cf6533",
          },
          {
            display: "Paused",
            state: "pausedCount",
            // icon: <Done className={classes.tabIcon} />,
            theme: "#f2c14e3d",
          },
          {
            display: "Stopped",
            state: "stoppedCount",
            // icon: <DoneAll className={classes.tabIcon} />,
            theme: "#ff808059",
          },
          {
            display: "Others",
            state: "othersCount",
            // icon: <DoneAll className={classes.tabIcon} />,
            theme: "#ff000059",
          },
        ];

  const toggleDialog = (event, reason) => {
    reason !== "backdropClick" && setOpenDialog(!openDialog);
  };

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await axios.post(`${URL}/api/meta-ad-counts`);
        setCounts(response.data[0]);
        if (!assistantFilter?.filter) {
          setPaginationCount(response.data[0]["all"]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchProjectAdsInsightData = async () => {
      try {
        const response = await axios.post(`${URL}/api/meta-ad-account-counts`);
        setCounts(response.data[0]);
        if (!assistantFilter?.filter) {
          setPaginationCount(response.data[0]["allCount"]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (location.pathname.includes("project")) {
      setPage("Project");
      fetchProjectAdsInsightData();
    } else {
      fetchCounts();
      setPage("Ads");
    }
  }, []);

  useEffect(() => {
    if (location.pathname.includes("project")) {
      fetchMetaAdAccountLists(assistantFilter);
    } else {
      fetchMetaAdLists(assistantFilter);
    }
  }, [tab, currentPage, rowsPerPage]);

  const DownloadReportHandler = async () => {
    if (location.pathname.includes("project")) {
      await fetchMetaAdAccountLists(assistantFilter, "report");
    } else {
      await fetchMetaAdLists(assistantFilter, "report");
    }
  };

  const makeCsvDataReport = (data) => {
    try {
      const headers = Array.from(
        new Set(data.flatMap((entry) => Object.keys(entry)))
      );
      // Convert to CSV
      const csvContent = [
        headers.join(","),
        ...data.map((entry) =>
          headers
            .map((header) =>
              entry[header] !== undefined && entry[header] !== null
                ? `"${entry[header].toString().replace(/\n/g, "\\n")}"`
                : ""
            )
            .join(",")
        ),
      ].join("\n");
      let start;
      let end;
      if (assistantFilter?.filter) {
        start = moment(
          assistantFilter?.fromAdStartDate
            ? assistantFilter.fromAdStartDate
            : assistantFilter.fromAdStopDate,
          "YYYY-MM-DD"
        ).isValid()
          ? moment(
              assistantFilter?.fromAdStartDate
                ? assistantFilter.fromAdStartDate
                : assistantFilter.fromAdStopDate
            ).format("DD-MM-YYYY")
          : null;
        end = moment(
          assistantFilter?.toAdStartDate
            ? assistantFilter.toAdStartDate
            : assistantFilter.toAdStopDate,
          "YYYY-MM-DD"
        ).isValid()
          ? moment(
              assistantFilter?.toAdStartDate
                ? assistantFilter.toAdStartDate
                : assistantFilter.toAdStopDate
            ).format("DD-MM-YYYY")
          : null;
      }

      // Create download link
      var downloadLink = document.createElement("a");
      const blob = new Blob([csvContent], { type: "text/csv" });
      var url = window.URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `Meta${
        location.pathname.includes("project") ? "-AdAccounts" : "-Ads"
      }${
        assistantFilter?.search
          ? `-${assistantFilter?.search}`
          : assistantFilter?.filter
          ? `-${start}-${end}`
          : `-${tab}`
      }-Report`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setAlertObj({
        alertType: "success",
        alertMsg: "Report successfully downloaded",
      });
    } catch (error) {
      setAlertObj({
        alertType: "error",
        alertMsg: "Unable to download report",
      });
    }
  };

  const fetchMetaAdLists = async (filterObj, purpose) => {
    purpose == "report" ? setReportLoading(true) : setIsLoading(true);

    const endPoint = `${URL}/api/meta-ad-lists?sort=dec`;
    const headers = {
      accept: "application/json",
    };
    const data = {
      skip: purpose == "report" ? 0 : currentPage * rowsPerPage,
      rowsPerPage: purpose == "report" ? paginationCount : rowsPerPage,
      tab,
      filterObj,
    };

    try {
      const response = await axios.post(endPoint, data, { headers });
      if (purpose != "report") {
        setAds(response?.data?.logs || []);
        if (filterObj?.filter || filterObj?.search) {
          setPaginationCount(response.data?.metaAdsCount);
        } else {
          const newTab = tabs.find((item) => item.display == tab);
          if (counts[newTab.state]) {
            setPaginationCount(counts[newTab.state]);
          }
        }
      } else {
        makeCsvDataReport(response?.data?.logs || []);
      }
      purpose == "report" ? setReportLoading(false) : setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setAlertObj({
        alertType: "error",
        alertMsg: "Unable to fetch data",
      });
      setIsLoading(false);
    }
  };

  const fetchMetaAdAccountLists = async (filterObj, purpose) => {
    purpose == "report" ? setReportLoading(true) : setIsLoading(true);
    const endpoint = `${URL}/api/meta-ad-acccount-lists?sort=dec`;
    const headers = {
      accept: "application/json",
    };
    const data = {
      skip: purpose == "report" ? 0 : currentPage * rowsPerPage,
      rowsPerPage: purpose == "report" ? paginationCount : rowsPerPage,
      tab,
      filterObj,
    };

    try {
      const response = await axios.post(endpoint, data, { headers });

      if (purpose != "report") {
        setAds(response?.data?.logs || []);
        if (filterObj?.filter || filterObj?.search) {
          setPaginationCount(response.data?.totalCount);
        } else {
          const newTab = tabs.find((item) => item.display == tab);
          if (counts[newTab.state]) {
            setPaginationCount(counts[newTab.state]);
          }
        }
      } else {
        makeCsvDataReport(response?.data?.logs || []);
      }

      purpose == "report" ? setReportLoading(false) : setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setAlertObj({
        alertType: "error",
        alertMsg: "Unable to fetch data",
      });
      purpose == "report" ? setReportLoading(false) : setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const setDate = (filterKey, startDate, endDate) => {
    const appliedData = { ...applied };
    appliedData[filterKey].startDate = startDate;
    appliedData[filterKey].endDate = endDate;

    setApplied(applied);
  };

  const setFocus = (filterKey, focusedInput) => {
    const appliedData = { ...applied };
    appliedData[filterKey].focusedInput = focusedInput;
    setApplied(appliedData);
  };

  const checkDateInput = (filterKey) => {
    const appliedData = { ...applied };
    const s = appliedData[filterKey];
    if (s.startDate && s.endDate) {
      return;
    } else {
      clearDate(filterKey);
    }
  };
  const clearDate = (filterKey) => {
    const appliedData = { ...applied };
    appliedData[filterKey].startDate = null;
    appliedData[filterKey].endDate = null;
    setApplied(appliedData);
  };

  const applyFilter = async () => {
    const filterObj = {};
    if (applied.adStartDate.startDate && applied.adStartDate.endDate) {
      filterObj.filter = true;
      filterObj.fromAdStartDate = applied.adStartDate.startDate._d;
      filterObj.toAdStartDate = applied.adStartDate.endDate._d;
    } else {
      delete filterObj.fromAdStartDate;
      delete filterObj.toAdStartDate;
    }
    if (applied.adStopDate.startDate && applied.adStopDate.endDate) {
      filterObj.filter = true;
      filterObj.fromAdStopDate = applied.adStopDate.startDate._d;
      filterObj.toAdStopDate = applied.adStopDate.endDate._d;
    } else {
      delete filterObj.fromAdStopDate;
      delete filterObj.toAdStopDate;
    }
    setAssistantFilter(filterObj);
    await fetchMetaAdLists(filterObj);
    setOpenDialog(!openDialog);
  };

  const onSnackbarClose = () => {
    setAlertObj({
      alertType: "",
      alertMsg: "",
    });
  };
  return (
    <div className={classes.root}>
      <Header
        businessTitle={page}
        hasReadAccess={true}
        hasWriteAccess={true}
        showSearch={true}
        buttonTitle={"Download Report"}
        buttonPrefixIcon={
          reportLoading ? (
            <CircularProgress size={20} color="white" />
          ) : (
            <GetApp />
          )
        }
        onButtonClick={() => DownloadReportHandler()}
        filter={assistantFilter?.search}
        searchPlaceholder={`Search name here`}
        handleSearch={(e) => {
          setAssistantFilter({ ...assistantFilter, search: e.target.value });
        }}
        handleEnter={(e) => {
          if (e.key === "Enter") {
            const filterObj = {};
            setRowsPerPage(10);
            setCurrentPage(0);
            filterObj.search = e.target.value;
            setAssistantFilter({ ...assistantFilter, filterObj });
            if (location.pathname.includes("project")) {
              fetchMetaAdAccountLists(filterObj);
            } else {
              fetchMetaAdLists(filterObj);
            }
          }
        }}
        searchDone={assistantFilter?.search}
        handleClear={() => {
          if (location.pathname.includes("project")) {
            fetchMetaAdAccountLists();
          } else {
            fetchMetaAdLists();
          }

          setAssistantFilter({});
          setRowsPerPage(10);
          setCurrentPage(0);
        }}
        // filter={searchFilter}
        toggleFilterDialog={
          location.pathname.includes("project") ? "" : toggleDialog
        }
        filterColor={false}
      />
      <Grid
        container
        alignItems="center"
        className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
      >
        <Tabs
          value={tab}
          onChange={(event, newValue) => {
            if (tab === newValue) return;

            const newTab = tabs.find((item) => item.display == newValue);
            setPaginationCount(counts[newTab.state]);
            setCurrentPage(0);
            setRowsPerPage(10);
            const filterObj = {};
            filterObj.filter = true;
            filterObj.search = "";
            setAssistantFilter(filterObj);
            setTab(newValue);
            setAssistantFilter({});
            setApplied({
              adStartDate: {
                startDate: null,
                endDate: null,
                focus: null,
              },
              adStopDate: {
                startDate: null,
                endDate: null,
                focus: null,
              },
            });
          }}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={{ className: classes.tabIndicator }}
        >
          {tabs.map((i) => (
            <Tab
              label={
                <>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={2}>
                      <Box
                        fontSize="16px"
                        fontWeight="bold"
                        textAlign="left"
                        width="100%"
                        style={{ marginTop: "5px" }}
                      >
                        {(assistantFilter?.filter || assistantFilter?.search) &&
                        tab === i.display
                          ? paginationCount
                          : counts?.[i.state] || 0}
                      </Box>
                    </Grid>
                    <Grid item xs={7}></Grid>
                  </Grid>
                  <Box fontSize="14px" width="100%" textAlign="left">
                    {(assistantFilter?.filter || assistantFilter?.search) &&
                    tab === i.display
                      ? "Result"
                      : i.display}
                  </Box>
                </>
              }
              value={i.display}
              style={{
                textTransform: "none",
                alignItems: "end",
                minWidth: 190,
                paddingBottom: 8,
                background: `linear-gradient(180deg, white, ${
                  tab === i.display ? i.theme : "white"
                })`,
              }}
            />
          ))}
        </Tabs>
      </Grid>
      <Box my={3} />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={11}>
          <Grid container spacing={2}>
            <div className={classes.tableContainer}>
              {page == "projects"}
              <AdsTable ads={ads} isLoading={isLoading} page={page} />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.fixedBottomContainer}>
        <TablePagination
          component="div"
          count={paginationCount}
          page={currentPage}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>

      <Dialog
        open={openDialog}
        maxWidth={"xs"}
        onClose={toggleDialog}
        aria-labelledby="form-dialog-title"
        className={classes.dialogContainer}
        fullScreen={mediaQuery}
        back
      >
        <DialogContent style={{ height: 500 }}>
          <Grid container spacing={2}>
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ paddingRight: 0, paddingTop: 0 }}
              xs={12}
            >
              <Typography variant="h3">Filter</Typography>
              <IconButton onClick={toggleDialog}>
                <Clear />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <DateFilter
                filterKey="adStartDate"
                filterName="Ad Start Date"
                classes={classes}
                applied={applied}
                setDate={setDate}
                setFocus={setFocus}
                checkDateInput={checkDateInput}
                clearDate={clearDate}
              />
            </Grid>
            <Grid item xs={12}>
              <DateFilter
                filterKey="adStopDate"
                filterName="Ad Stop Date"
                classes={classes}
                applied={applied}
                setDate={setDate}
                setFocus={setFocus}
                checkDateInput={checkDateInput}
                clearDate={clearDate}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            item
            container
            justifyContent="flex-end"
            xs={12}
            style={{ paddingRight: 24, paddingBottom: mediaQuery ? 64 : 16 }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={applyFilter}
              style={{ margin: 0 }}
            >
              Apply
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!alertObj.alertMsg}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={onSnackbarClose}
        autoHideDuration={3000}
      >
        <Alert severity={alertObj.alertType} onClose={onSnackbarClose}>
          {alertObj.alertMsg}
        </Alert>
      </Snackbar>
    </div>
  );
};

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(247,247,247)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  dialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        // height: "100%",
        // maxHeight: "100%",
        margin: "0",
        borderRadius: "0px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        // height: "unset",
        // maxHeight: "unset",
        // minHeight: "600px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
  pageTitleContainer: {
    position: "sticky",
    // zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
    height: "71px",
  },
  tabIndicator: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
  },
  tableContainer: {
    top: "60px",
    height: "calc(100vh - 260px)",
    width: "100%",
    overflow: "hidden",
    // background: "red",
    boxSizing: "border-box",
    position: "sticky",
    [theme.breakpoints.down("xs")]: {
      // position top + bottombar height + bottom padding + navbar
      marginTop: 48,
      height: "calc(100vh - 70px - 50px - 10px - 50px)",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  fixedBottomContainer: {
    position: "fixed",
    height: "60px",
    borderTop: "1px solid lightgrey",
    background: "white",
    bottom: "0",
    left: "71px",
    right: "0",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      height: "50px",
    },
  },
});
function DateFilter({
  filterKey,
  applied,
  setFocus,
  setDate,
  checkDateInput,
  clearDate,
  ...props
}) {
  const { classes, filterName } = props;
  const A = applied[filterKey];
  return (
    <Box mt={1} mb={1} className={classes.filterTypeContainer}>
      <Grid container alignItems="center">
        {A.startDate && A.endDate ? (
          <Grid item xs={12} md={1}>
            <IconButton
              color="primary"
              size="small"
              variant="filled"
              onClick={() => clearDate(filterKey)}
            >
              <Clear />
            </IconButton>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={12} md={3}>
          <Typography variant="body1" style={{ marginRight: 8, minWidth: 100 }}>
            {filterName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <DateRangePicker
            startDatePlaceholderText="From"
            endDatePlaceholderText="To"
            onClose={() => checkDateInput(filterKey)}
            startDateId="startDate"
            endDateId="endDate"
            startDate={A.startDate}
            endDate={A.endDate}
            onDatesChange={({ startDate, endDate }) =>
              setDate(filterKey, startDate, endDate)
            }
            focusedInput={A.focusedInput}
            onFocusChange={(focusedInput) => setFocus(filterKey, focusedInput)}
            navPosition="navPositionTop"
            numberOfMonths={1}
            navPrev={
              <NavigationWrapper classes={classes}>
                <NavigateBefore />
              </NavigationWrapper>
            }
            navNext={
              <NavigationWrapper classes={classes}>
                <NavigateNext />
              </NavigationWrapper>
            }
            hideKeyboardShortcutsPanel
            customArrowIcon={null}
            screenReaderInputMessage={" "}
            small
            readOnly
            isOutsideRange={(day) =>
              filterKey === "adStopDate" ? false : moment().diff(day) < 0
            }
          />
        </Grid>
      </Grid>
    </Box>
  );

  // function setToday()
}

// Navigattion icons used in date fliters
function NavigationWrapper(props) {
  const { classes } = props;
  return (
    <div className={classes.navigationWrapper}>
      <IconButton size="small">{props.children}</IconButton>
    </div>
  );
}

const connectMetaAds = connect(
  (state) => ({
    partner: state.partner.partner,
    planFamilies: state.planFamilies.allPlanFamilies,
    wccPlans: state.wccPlans.allWccPlans,
    whatsappPaymentPlans: state.whatsappPaymentsPlans.allPlans,
    partnerAgents: state.partnerAgents.partnerAgents,
    user: state.login.user,
  }),
  {
    fetchWccPlans,
    addPlanFamily,
    updateWccFamily,
    updateWhatsappPaymentsPlanFamily,
    fetchPartnerDetails,
  }
)(MetaAds);

export default withStyles(styles)(
  withMediaQuery("(max-width:600px)")(connectMetaAds)
);
