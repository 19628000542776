import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { partnerURL } from "../../../config/config";
import {
  Typography,
  withStyles,
  Box,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ComposedGraph from "../../../commons/Graphs/ComposedGraph";
import DateFilter from "../../../commons/Filters/DateFilter";
import { isoToName } from "../../../config/dialCodes";
import { GetApp } from "@material-ui/icons";
import DownloadReport from "./DownloadReport";

const current = moment();
const INTERVAL_VALUES = {
  Today: {
    fromDate: current.clone().startOf("day"),
    toDate: current,
  },
  Yesterday: {
    fromDate: current.clone().subtract(1, "days").startOf("day"),
    toDate: current.clone().subtract(1, "days").endOf("day"),
  },
  "Last 7 days": {
    fromDate: current.clone().subtract(7, "days").startOf("day"),
    toDate: current,
  },
  "Last 30 days": {
    fromDate: current.clone().subtract(30, "days").startOf("day"),
    toDate: current,
  },
};
const INTERVALS = Object.keys(INTERVAL_VALUES);

function WccAnalytics(props) {
  const { classes, projectId, partnerId, currency } = props;
  const [loading, setLoading] = useState(true);
  const [interval, setInterval] = useState("Last 7 days");
  const [graphData, setGraphData] = useState([]);
  const [creditGraphData, setCreditGraphData] = useState([]);
  const [startDate, setStartDate] = useState(
    INTERVAL_VALUES[interval].fromDate
  );
  const [endDate, setEndDate] = useState(INTERVAL_VALUES[interval].toDate);
  const [focusedInput, setFocusedInput] = useState();
  const eventTypes = {
    sent: 0,
    delivered: 0,
    read: 0,
    failed: 0,
  };
  const creditEventTypes = {
    tcBicCount: 0,
    tcBicUsage: 0,
    tcUicCount: 0,
    tcUicUsage: 0,
    tcAcCount: 0,
    tcAcUsage: 0,
    tcMcCount: 0,
    tcMcUsage: 0,
    tcUcCount: 0,
    tcUcUsage: 0,
    tcScCount: 0,
    tcScUsage: 0,
    tcTotalUsage: 0,
    cbBicCount: 0,
    cbBicUsage: 0,
    cbUicCount: 0,
    cbUicUsage: 0,
    cbAcCount: 0,
    cbAcUsage: 0,
    cbMcCount: 0,
    cbMcUsage: 0,
    cbUcCount: 0,
    cbUcUsage: 0,
    cbScCount: 0,
    cbScUsage: 0,
    cbTotalUsage: 0,
  };
  const [stats, setStats] = useState({ ...eventTypes });
  const [creditStats, setCreditStats] = useState({ ...creditEventTypes });
  const [analytics, setAnalytics] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("ALL");
  const [countries, setCountries] = useState(["ALL"]);
  const [showReport, setShowReport] = useState(false);
  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current) {
      const weekly = INTERVAL_VALUES["Last 7 days"];
      getAnalytics(weekly.fromDate, weekly.toDate);
      didMount.current = true;
      return;
    }

    formatByCountry(selectedCountry);
  }, [selectedCountry]);

  const getAnalytics = async (startDate, endDate) => {
    try {
      let url =
        partnerURL +
        `/partner/${partnerId}/wcc-analytics?projectId=${projectId}`;
      if (startDate) url += `&startDate=${startDate}`;
      if (endDate) url += `&endDate=${endDate}`;
      const { data } = await axios.get(url);
      setAnalytics(data.wccAnalytics);
      formatGraphData(data.wccAnalytics);
    } catch (err) {
      console.log("Unable to fetch WCC Analytics ->", err);
    }
  };

  const setDateRange = (startDate, endDate) => {
    if (startDate && endDate) {
      setStartDate(startDate);
      setEndDate(endDate);
      setLoading(true);
      getAnalytics(startDate, endDate);
    }
  };

  const setFocus = (focusedInput) => {
    const picker = document.getElementsByClassName("DateInput");
    if (picker[0])
      picker[0].style.border =
        focusedInput === "startDate" ? "1px solid grey" : "1px solid lightgrey";
    if (picker[1])
      picker[1].style.border =
        focusedInput === "endDate" ? "1px solid grey" : "1px solid lightgrey";
    setFocusedInput(focusedInput);
  };

  const formatGraphData = (analyticsData) => {
    const graphData = [];
    const msgObj = {
      total: { ...eventTypes },
      data: {
        sent: {},
        delivered: {},
        read: {},
        failed: {},
      },
    };

    for (const chat of analyticsData) {
      const sentDate = moment(chat.dayDate).format("MM/DD/YYYY");

      for (const key in msgObj.data) {
        const value = chat[`${key}ChatCount`] || 0;
        msgObj.data[key][sentDate] = msgObj.data[key][sentDate]
          ? msgObj.data[key][sentDate] + value
          : value;
        msgObj.total[key] += value;
      }
    }
    // console.log(sent, read, delivered, failed);
    const MONTHS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const datesSet = new Set([
      ...Object.keys(msgObj.data.sent),
      ...Object.keys(msgObj.data.delivered),
      ...Object.keys(msgObj.data.read),
      ...Object.keys(msgObj.data.failed),
    ]);
    const dates = Array.from(datesSet).sort((a, b) =>
      moment(a).diff(moment(b), "minutes")
    );

    const keyMap = {
      sent: "Sent",
      delivered: "Delivered",
      read: "Read",
      failed: "Failed",
    };

    for (const date of dates) {
      const arr = date.split("/");
      const calcDate = arr[1] + " " + MONTHS[Number(arr[0]) - 1];
      const obj = {
        date: calcDate,
      };
      for (const key in msgObj.data) {
        const updatedKey = keyMap[key];
        obj[updatedKey] = msgObj.data[key][date] || 0;
      }
      graphData.push(obj);
    }

    setGraphData(graphData);
    setStats(msgObj.total);
    formatByCountry("ALL", analyticsData);
    getCountries(analyticsData);
  };

  const formatByCountry = (country, analyticsData) => {
    const creditGraphData = [];
    const creditObj = {
      total: { ...creditEventTypes },
      data: {
        tcBicCount: {},
        tcBicUsage: {},
        tcUicCount: {},
        tcUicUsage: {},
        tcAcUsage: {}, // Ac for authentication conversation
        tcAcCount: {},
        tcScUsage: {},
        tcScCount: {}, // sc for service conversation
        tcMcUsage: {},
        tcMcCount: {}, // mc for marketing conversation
        tcUcUsage: {},
        tcUcCount: {}, // uc for utility conversation
        tcTotalUsage: {},
        cbBicCount: {},
        cbBicUsage: {},
        cbUicCount: {},
        cbUicUsage: {},
        cbAcUsage: {},
        cbAcCount: {},
        cbScUsage: {},
        cbScCount: {},
        cbMcUsage: {},
        cbMcCount: {},
        cbUcUsage: {},
        cbUcCount: {},
        cbTotalUsage: {},
      },
    };
    const creditMap =
      country === "ALL"
        ? {
            tcBicCount: ["bicTemplateCreditMetrics", "count"],
            tcBicUsage: ["bicTemplateCreditMetrics", "creditUsage"],
            tcUicCount: ["uicTemplateCreditMetrics", "count"],
            tcUicUsage: ["uicTemplateCreditMetrics", "creditUsage"],
            tcAcCount: ["acTemplateCreditMetrics", "count"],
            tcAcUsage: ["acTemplateCreditMetrics", "creditUsage"],
            tcScCount: ["scTemplateCreditMetrics", "count"],
            tcScUsage: ["scTemplateCreditMetrics", "creditUsage"],
            tcMcCount: ["mcTemplateCreditMetrics", "count"],
            tcMcUsage: ["mcTemplateCreditMetrics", "creditUsage"],
            tcUcCount: ["ucTemplateCreditMetrics", "count"],
            tcUcUsage: ["ucTemplateCreditMetrics", "creditUsage"],

            cbAcCount: ["acCentralBalanceMetrics", "count"],
            cbAcUsage: ["acCentralBalanceMetrics", "creditUsage"],
            cbScCount: ["scCentralBalanceMetrics", "count"],
            cbScUsage: ["scCentralBalanceMetrics", "creditUsage"],
            cbMcCount: ["mcCentralBalanceMetrics", "count"],
            cbMcUsage: ["mcCentralBalanceMetrics", "creditUsage"],
            cbUcCount: ["ucCentralBalanceMetrics", "count"],
            cbUcUsage: ["ucCentralBalanceMetrics", "creditUsage"],
            cbBicCount: ["bicCentralBalanceMetrics", "count"],
            cbBicUsage: ["bicCentralBalanceMetrics", "creditUsage"],
            cbUicCount: ["uicCentralBalanceMetrics", "count"],
            cbUicUsage: ["uicCentralBalanceMetrics", "creditUsage"],
          }
        : {
            tcBicCount: [
              "bicTemplateCreditMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "count",
            ],
            tcBicUsage: [
              "bicTemplateCreditMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "amount",
            ],
            tcUicCount: [
              "uicTemplateCreditMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "count",
            ],
            tcUicUsage: [
              "uicTemplateCreditMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "amount",
            ],
            tcAcCount: [
              "acTemplateCreditMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "count",
            ],
            tcAcUsage: [
              "acTemplateCreditMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "count",
            ],
            tcMcCount: [
              "mcTemplateCreditMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "count",
            ],
            tcMcUsage: [
              "mcTemplateCreditMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "count",
            ],
            tcScCount: [
              "scTemplateCreditMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "count",
            ],
            tcScUsage: [
              "scTemplateCreditMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "count",
            ],
            tcUcCount: [
              "ucTemplateCreditMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "count",
            ],
            tcUcUsage: [
              "ucTemplateCreditMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "count",
            ],

            cbBicCount: [
              "bicCentralBalanceMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "count",
            ],
            cbBicUsage: [
              "bicCentralBalanceMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "amount",
            ],
            cbUicCount: [
              "uicCentralBalanceMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "count",
            ],
            cbUicUsage: [
              "uicCentralBalanceMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "amount",
            ],
            cbAcCount: [
              "acCentralBalanceMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "count",
            ],
            cbAcUsage: [
              "acCentralBalanceMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "amount",
            ],
            cbMcCount: [
              "acCentralBalanceMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "count",
            ],
            cbMcUsage: [
              "acCentralBalanceMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "amount",
            ],
            cbScCount: [
              "scCentralBalanceMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "count",
            ],
            cbScUsage: [
              "scCentralBalanceMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "amount",
            ],
            cbUcCount: [
              "ucCentralBalanceMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "count",
            ],
            cbUcUsage: [
              "ucCentralBalanceMetrics",
              "creditUsageCountryWise",
              `${country}`,
              "amount",
            ],
          };
    const data = analyticsData || analytics;
    const roundNumber = (num) => Math.round((num + Number.EPSILON) * 100) / 100;
    const creditDatesSet = new Set();
    for (const chat of data) {
      const sentDate = moment(chat.dayDate).format("MM/DD/YYYY");
      creditDatesSet.add(sentDate);
      for (const key in creditMap) {
        let value = creditMap[key].reduce(
          (acc, curr) => (acc && curr ? acc[curr] : 0),
          chat
        );
        if (
          ![
            "tcBicCount",
            "tcUicCount",
            "cbBicCount",
            "cbUicCount",
            "tcAcCount",
            "cbAcCount",
            "tcMcCount",
            "cbMcCount",
            "tcScCount",
            "cbScCount",
            "tcUcCount",
            "cbUcCount",
          ].includes(key)
        ) {
          value /= 100000;
        }

        // const value = chat[creditMap[key]] || 0;
        creditObj.data[key][sentDate] = roundNumber(
          creditObj.data[key][sentDate]
            ? creditObj.data[key][sentDate] + value
            : value
        );
        creditObj.total[key] = roundNumber(creditObj.total[key] + value);
      }
      for (const key of ["tc", "cb"]) {
        const netValue = roundNumber(
          creditObj.data[`${key}BicUsage`][sentDate] +
            creditObj.data[`${key}UicUsage`][sentDate] +
            creditObj.data[`${key}AcUsage`][sentDate] +
            creditObj.data[`${key}McUsage`][sentDate] +
            creditObj.data[`${key}UcUsage`][sentDate] +
            creditObj.data[`${key}ScUsage`][sentDate]
        );
        const netCount =
          creditObj.data[`${key}BicCount`][sentDate] +
          creditObj.data[`${key}UicCount`][sentDate] +
          creditObj.data[`${key}AcCount`][sentDate] +
          creditObj.data[`${key}ScCount`][sentDate] +
          creditObj.data[`${key}UcCount`][sentDate] +
          creditObj.data[`${key}McCount`][sentDate];

        creditObj.data[`${key}TotalUsage`][sentDate] = roundNumber(
          creditObj.data[`${key}TotalUsage`][sentDate]
            ? creditObj.data[`${key}TotalUsage`][sentDate] + netValue
            : netValue
        );
        creditObj.total[`${key}TotalUsage`] = roundNumber(
          creditObj.total[`${key}TotalUsage`] + netCount
        );
      }
    }
    // console.log(sent, read, delivered, failed);
    const MONTHS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const creditDates = Array.from(creditDatesSet).sort((a, b) =>
      moment(a).diff(moment(b), "minutes")
    );

    const keyMap = {
      tcBicCount: "Template Credit BIC Count",
      tcBicUsage: "BIC Template Credit Usage",
      tcUicCount: "Template Credit UIC Count",
      tcUicUsage: "UIC Template Credit Usage",
      tcAcCount: "AC Template Credit Count",
      tcAcUsage: "AC Template Credit Usage",
      tcUcCount: "UC Template Credit Count",
      tcUcUsage: "UC Template Credit Usage",
      tcMcCount: "MC Template Credit Count",
      tcMcUsage: "MC Template Credit Usage",
      tcScUsage: "SC Template Credit Usage",
      tcScCount: "SC Template Credit Count",
      tcTotalUsage: "Total Template Credit Usage",

      cbBicCount: "Central Balance BIC Count",
      cbBicUsage: "BIC Central Balance Usage",
      cbUicCount: "Central Balance UIC Count",
      cbUicUsage: "UIC Central Balance Usage",
      cbAcCount: "Central Balance AC Count",
      cbAcUsage: "AC Central Balance Usage",
      cbScCount: "Central Balance SC Count",
      cbScUsage: "SC Central Balance Usage",
      cbUcCount: "Central Balance UC Count",
      cbUcUsage: "UC Central Balance Usage",
      cbMcCount: "Central Balance MC Count",
      cbMcUsage: "MC Central Balance Usage",
      cbTotalUsage: "Total Central Balance Usage",
    };

    for (const date of creditDates) {
      const arr = date.split("/");
      const calcDate = arr[1] + " " + MONTHS[Number(arr[0]) - 1];
      const obj = {
        date: calcDate,
      };

      for (const key in creditObj.data) {
        const updatedKey = keyMap[key];
        obj[updatedKey] = creditObj.data[key][date] || 0;
      }
      creditGraphData.push(obj);
    }

    setCreditGraphData(creditGraphData);
    setCreditStats(creditObj.total);
    setLoading(false);
  };

  const getCountries = (analyticsData) => {
    const countries = new Set();
    for (const data of analyticsData) {
      const uicCountries = Object.keys(
        data?.uicTemplateCreditMetrics?.creditUsageCountryWise || {}
      );
      uicCountries.forEach((item) => countries.add(item));

      const bicCountries = Object.keys(
        data?.bicTemplateCreditMetrics?.creditUsageCountryWise || {}
      );
      bicCountries.forEach((item) => countries.add(item));
    }
    setCountries([...countries, "ALL"]);
  };

  console.log(creditStats);
  console.log(creditGraphData);
  const keys = ["Sent", "Delivered", "Read", "Failed"];
  return (
    <Box width="100%" style={{ paddingBottom: 100 }}>
      <Grid
        container
        alignItems="center"
        className={classes.dateFilterContainer}
        style={{ margin: "1em", overflow: "hidden" }}
      >
        <Box
          p="0em 2em"
          mt="1em"
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h5" component="span">
              Messages
            </Typography>
            <Typography variant="body2" component="span">
              {window.innerWidth < 400 ? " / day" : " (per day)"}
            </Typography>
          </Box>
          <Box>
            <Button
              style={{
                left: "4px",
              }}
              color="primary"
              size="small"
              onClick={() => {
                setShowReport(true);
              }}
              variant="outlined"
              startIcon={<GetApp />}
            >
              {window.innerWidth < 400 ? "Report" : "Download Report"}
            </Button>
          </Box>
        </Box>

        <React.Fragment>
          <Grid item>
            <Box p={{ xs: 2, md: 3 }} mr={2}>
              <Autocomplete
                disableClearable
                value={interval}
                options={INTERVALS.map((option) => option)}
                onChange={(e, value) => {
                  const obj = INTERVAL_VALUES[value];
                  setInterval(value);
                  setStartDate(obj.fromDate);
                  setEndDate(obj.toDate);
                  setLoading(true);
                  getAnalytics(obj.fromDate, obj.toDate);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.textField}
                    InputProps={{ ...params.InputProps }}
                    placeholder="Select duration"
                    name="Duration"
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item>
            <DateFilter
              startDate={startDate}
              endDate={endDate}
              focusedInput={focusedInput}
              setDate={setDateRange}
              setFocus={setFocus}
              classes={classes}
              minDate={moment(new Date("04/25/2023"))}
            />
          </Grid>

          <Grid item xs={12} container>
            <Grid item xs={6} sm={3} className={classes.dataContainer}>
              <Typography variant="body2" gutterBottom>
                Sent
              </Typography>
              <Typography variant="body2">{stats.sent}</Typography>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.dataContainer}>
              <Typography variant="body2" gutterBottom>
                Delivered
              </Typography>
              <Typography variant="body2">{stats.delivered}</Typography>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.dataContainer}>
              <Typography variant="body2" gutterBottom>
                Read
              </Typography>
              <Typography variant="body2">{stats.read}</Typography>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.dataContainer}>
              <Typography variant="body2" gutterBottom>
                Failed
              </Typography>
              <Typography variant="body2">{stats.failed}</Typography>
            </Grid>
          </Grid>
        </React.Fragment>

        <ComposedGraph
          data={[...graphData]}
          keys={keys}
          isLoading={loading}
          color="hsl(83deg 91% 50% / 44%)"
        />

        <WccUsage
          classes={classes}
          creditStats={creditStats}
          loading={loading}
          creditGraphData={creditGraphData}
          countries={countries}
          selectedCountry={selectedCountry}
          currency={currency}
          setSelectedCountry={setSelectedCountry}
        />
      </Grid>
      <DownloadReport
        classes={classes}
        showReport={showReport}
        setShowReport={setShowReport}
        currency={currency}
        projectId={projectId}
        partnerId={partnerId}
        dialogHeading={"Download CSV Report"}
        dialogBody={"Select date range to download combined analytics"}
      />
    </Box>
  );
}

function WccUsage(props) {
  const { classes, currency, creditStats, creditGraphData, loading } = props;
  const { countries, selectedCountry, setSelectedCountry } = props;
  const tcTotal =
    Math.round(
      (creditStats.tcAcUsage +
        creditStats.tcMcUsage +
        creditStats.tcUcUsage +
        creditStats.tcScUsage +
        Number.EPSILON) *
        100
    ) / 100;
  const cbTotal =
    Math.round(
      (creditStats.cbAcUsage +
        creditStats.cbMcUsage +
        creditStats.cbUcUsage +
        creditStats.cbScUsage +
        Number.EPSILON) *
        100
    ) / 100;
  return (
    <>
      <Autocomplete
        disableClearable
        selectOnFocus={false}
        options={countries}
        getOptionLabel={(option) => {
          return isoToName[option] || "ALL";
        }}
        onChange={(e, value, reason) => {
          setSelectedCountry(value);
        }}
        value={selectedCountry}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.countryDropdown}
            InputProps={{ ...params.InputProps }}
            placeholder="Select Country"
          />
        )}
      />
      <Grid
        item
        xs={12}
        container
        spacing={2}
        className={classes.totalContainer}
      >
        <Grid item xs={12} md={12}>
          <Typography variant="body2" gutterBottom>
            Total Template Credit Usage
          </Typography>
          <Typography variant="body2">
            {currency === "USD" ? "$" : "₹"}
            {tcTotal}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            Template Credit BIC Count
          </Typography>
          <Typography variant="body2">{creditStats.tcBicCount}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            BIC Template Credit Usage
          </Typography>
          <Typography variant="body2">
            {currency === "USD" ? "$" : "₹"}
            {creditStats.tcBicUsage}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            UIC Count
          </Typography>
          <Typography variant="body2">{creditStats.tcUicCount}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            UIC Template Credit Usage
          </Typography>
          <Typography variant="body2">
            {currency === "USD" ? "$" : "₹"}
            {creditStats.tcUicUsage}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            AC Template Credit Count
          </Typography>
          <Typography variant="body2">{creditStats.tcAcCount}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            AC Template Credit Usage
          </Typography>
          <Typography variant="body2">
            {currency === "USD" ? "$" : "₹"}
            {creditStats.tcAcUsage}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            UC Template Credit Count
          </Typography>
          <Typography variant="body2">{creditStats.tcUcCount}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            UC Template Credit Usage
          </Typography>
          <Typography variant="body2">
            {currency === "USD" ? "$" : "₹"}
            {creditStats.tcUcUsage}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            SC Template Credit Count
          </Typography>
          <Typography variant="body2">{creditStats.tcScCount}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            SC Template Credit Usage
          </Typography>
          <Typography variant="body2">
            {currency === "USD" ? "$" : "₹"}
            {creditStats.tcScUsage}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            MC Template Credit Count
          </Typography>
          <Typography variant="body2">{creditStats.tcMcCount}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            MC Template Credit Usage
          </Typography>
          <Typography variant="body2">
            {currency === "USD" ? "$" : "₹"}
            {creditStats.tcMcUsage}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        spacing={2}
        className={classes.totalContainer}
      >
        <Grid item xs={12} md={12}>
          <Typography variant="body2" gutterBottom>
            Total Central Balance Usage
          </Typography>
          <Typography variant="body2">
            {currency === "USD" ? "$" : "₹"}
            {cbTotal}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            Central Balance BIC Count
          </Typography>
          <Typography variant="body2">{creditStats.cbBicCount}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            BIC Central Balance Usage
          </Typography>
          <Typography variant="body2">
            {currency === "USD" ? "$" : "₹"}
            {creditStats.cbBicUsage}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            UIC Central Balance Count
          </Typography>
          <Typography variant="body2">{creditStats.cbUicCount}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            UIC Central Balance Usage
          </Typography>
          <Typography variant="body2">
            {currency === "USD" ? "$" : "₹"}
            {creditStats.cbUicUsage}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            AC Central Balance Count
          </Typography>
          <Typography variant="body2">{creditStats.cbAcCount}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            AC Central Balance Usage
          </Typography>
          <Typography variant="body2">
            {currency === "USD" ? "$" : "₹"}
            {creditStats.cbAcUsage}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            UC Central Balance Count
          </Typography>
          <Typography variant="body2">{creditStats.cbUcCount}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            UC Central Balance Usage
          </Typography>
          <Typography variant="body2">
            {currency === "USD" ? "$" : "₹"}
            {creditStats.cbUcUsage}
          </Typography>
        </Grid>

        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            SC Central Balance Count
          </Typography>
          <Typography variant="body2">{creditStats.cbScCount}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            SC Central Balance Usage
          </Typography>
          <Typography variant="body2">
            {currency === "USD" ? "$" : "₹"}
            {creditStats.cbScUsage}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            MC Central Balance Count
          </Typography>
          <Typography variant="body2">{creditStats.cbMcCount}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="body2" gutterBottom>
            MC Central Balance Usage
          </Typography>
          <Typography variant="body2">
            {currency === "USD" ? "$" : "₹"}
            {creditStats.cbMcUsage}
          </Typography>
        </Grid>
      </Grid>
      <ComposedGraph
        data={[...creditGraphData]}
        keys={[
          "Total Central Balance Usage",
          "Central Balance BIC Count",
          "BIC Central Balance Usage",
          "Central Balance UIC Count",
          "UIC Central Balance Usage",
          "Central Balance AC Count",
          "AC Central Balance Usage",
          "Central Balance SC Count",
          "SC Central Balance Usage",
          "Central Balance UC Count",
          "UC Central Balance Usage",
          "Central Balance MC Count",
          "MC Central Balance Usage",
        ]}
        isLoading={loading}
        color="hsl(64deg 86% 47% / 57%)"
      />
      <ComposedGraph
        data={[...creditGraphData]}
        keys={[
          "Total Template Credit Usage",
          "Template Credit BIC Count",
          "BIC Template Credit Usage",
          "Template Credit UIC Count",
          "UIC Template Credit Usage",
          "Template Credit AC Count",
          "AC Template Credit Usage",
          "Template Credit SC Count",
          "SC Template Credit Usage",
          "Template Credit UC Count",
          "UC Template Credit Usage",
          "Template Credit MC Count",
          "MC Template Credit Usage",
        ]}
        isLoading={loading}
        color="hsl(64deg 86% 47% / 57%)"
      />
    </>
  );
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // marginBottom: "5em",
    // paddingBottom: "80px"
  },
  roundedBox: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "12px",
    backgroundColor: "white",
    border: "1px black",
    margin: "1em",
    padding: "1.2em",
    paddingLeft: "2em",
  },
  messageCountContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "12px",
    backgroundColor: "white",
    border: "1px black",
    margin: "1em",
    padding: "1.2em",
    paddingLeft: "2em",
    paddingBottom: "10px",
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    boxShadow: "0 0 11px rgb(230 230 230)",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  tabPageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 124,
    boxShadow: "0 0 12px rgb(171 170 170)",
    boxSizing: "border-box",
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  container: {
    background: "white",
    borderRadius: "8px",
  },
  dataContainer: {
    padding: "2em",
    minWidth: "10vw",
  },
  agentMessage: {
    textAlign: "justify",
    margin: "0.1em 0.5em",
    backgroundColor: "#EBF5F3",
    color: theme.palette.primary.main,
    borderRadius: "22px",
    width: "100%",
    marginTop: "auto",
    overflow: "hidden",
    whiteSpace: "pre-line",
    wordBreak: "break-word",
  },
  textField: {
    width: 200,
    padding: "2px 16px",
  },
  dateFilterContainer: {
    padding: "0.5em",
    backgroundColor: "white",
    borderRadius: "1em",
    margin: "0 1em 1em",
    width: "auto",
  },
  countryDropdown: {
    width: 200,
    float: "right",
    marginLeft: 24,
    padding: "2px 16px",
  },
  totalContainer: {
    [theme.breakpoints.down("md")]: {
      margin: "18px 12px 0 0",
    },
    [theme.breakpoints.up("md")]: {
      margin: "24px 0 0 18px",
    },
  },
  changeQualityStatus: {
    "& .MuiChip-labelSmall": {
      color: "white",
    },
  },
  attachTooltipPopper: {
    "& .MuiTooltip-tooltip": {
      padding: 0,
      background: "#EBF5F3",
      borderRadius: 8,
      boxShadow: "0 0 6px #c6c6c6",
    },
    "& .MuiTooltip-arrow": {
      color: "#EBF5F3",
      filter: "drop-shadow(0px -2px 1px #ddd)",
    },
  },
  tabIndicator_OVERVIEW: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    maxWidth: "150px",
  },
  tabIndicator_SENT: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    maxWidth: "150px",
    background: "#F2C14E",
  },
  tabIndicator_DELIVERED: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    maxWidth: "150px",
    background: "#BDBF00",
  },
  tabIndicator_READ: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    maxWidth: "150px",
    background: "rgb(8, 207, 101)",
  },
  tabIndicator_REPLIED: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    maxWidth: "150px",
    background: "rgb(83, 189, 235)",
  },
  tabIndicator_CLICKED: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    maxWidth: "150px",
    background: "#a48ed2",
  },
  tabIndicator_FAILED: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
    maxWidth: "150px",
    background: "#F78154",
  },
  tabIcon: {
    fontSize: 18,
    position: "relative",
    right: 4,
    top: 4,
  },
  previewWrapper: {
    "& .arrowTop": {
      bottom: -80,
    },
  },
  qualityStatus: {
    "& .MuiChip-label": {
      color: "white",
    },
  },
  menuRoot: {
    top: "44px !important",
    left: "-30px !important",
  },
  dialogContainer: {
    "& .MuiDialogContent-root": { overflow: "hidden" },
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "650px",
        minWidth: "100px",
        height: "550px",
        margin: "0",
        borderRadius: "6px",
      },
      [theme.breakpoints.up("md")]: {
        width: "650px",
        height: "550px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
});

export default withStyles(styles)(WccAnalytics);
