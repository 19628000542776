import React, { useState } from "react";
import DateFilter from "../../../commons/Filters/DateFilter";
import axios from "axios";
import {
  Typography,
  Grid,
  CircularProgress,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from "@material-ui/core";
import { GetApp, Clear } from "@material-ui/icons";
import { partnerURL } from "../../../config/config";
import { Autocomplete } from "@material-ui/lab";

const ReasonCode = [
  "ADDON_SERVICE_PURCHASED",
  "PLAN_PURCHASE",
  "ADDON_RENEWED",
  "ADDON_SERVICE_SWITCHED",
  "PARTNERSHIP_SWITCHED",
  "PARTNERSHIP_RENEWAL_FAILURE",
  "PWC",
  "WCC",
  "ADDON_PURCHASED",
  "PARTNERSHIP_RENEWED",
  "BILLING_FAILURE",
  "ADDON_SERVICE_RENEWED",
  "ADDON_SWITCH",
  "ADDON_UPDATED",
  "CENTRAL_BALANCE_UPDATE",
  "PLAN_SWITCH",
  "MESSAGE_DELIVERED",
  "PLAN_RENEWED",
];

export default function DownloadReport(props) {
  const {
    classes,
    showReport,
    setShowReport,
    projectId,
    partnerId,
    currency,
    getConvoReport,
    reportLoading,
    convoReportDownload,
    dialogHeading,
    dialogBody,
  } = props;
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedReasonCodes, setSelectedReasonCodes] = useState();

  const resetFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedReasonCodes([]);
  };

  const setDateRange = (startDate, endDate) => {
    if (startDate) setStartDate(startDate);
    if (endDate) setEndDate(endDate);
  };

  const setFocus = (focusedInput) => {
    const picker = document.getElementsByClassName("DateInput");
    if (picker[0])
      picker[0].style.border =
        focusedInput === "startDate" ? "1px solid grey" : "1px solid lightgrey";
    if (picker[1])
      picker[1].style.border =
        focusedInput === "endDate" ? "1px solid grey" : "1px solid lightgrey";
    setFocusedInput(focusedInput);
  };

  const generateReport = async () => {
    setLoading(true);
    try {
      let url =
        partnerURL +
        `/partner/${partnerId}/wcc-analytics?projectId=${projectId}`;
      if (startDate) url += `&startDate=${startDate}`;
      if (endDate) url += `&endDate=${endDate}`;
      const response = await axios.get(url);

      const report = response.data?.wccAnalytics || [];

      const currencySign = currency === "USD" ? "$" : "₹";
      const roundNumber = (value) =>
        currencySign + (value ? (value / 100000).toFixed(2) : "0");
      //update
      const data = report.map((row) => ({
        Date: row.dayDate
          ? new Date(row.dayDate).toLocaleDateString([], {
              month: "short",
              day: "numeric",
              year: "numeric",
            })
          : null,
        "Sent Chat Count": row.sentChatCount || 0,
        "Delivered Chat Count": row.deliveredChatCount || 0,
        "Read Chat Count": row.readChatCount || 0,
        "Failed Chat Count": row.failedChatCount || 0,

        "Template Credit MC Count": row.mcTemplateCreditMetrics?.count || 0,
        "Template Credit MC Usage": roundNumber(
          row.mcTemplateCreditMetrics?.creditUsage
        ),

        "Central Balance MC Count": row.mcCentralBalanceMetrics?.count || 0,
        "Central Balance MC Usage": roundNumber(
          row.mcCentralBalanceMetrics?.creditUsage
        ),

        "Template Credit UC Count": row.ucTemplateCreditMetrics?.count || 0,
        "Template Credit UC Usage": roundNumber(
          row.ucTemplateCreditMetrics?.creditUsage
        ),

        "Central Balance UC Count": row.ucCentralBalanceMetrics?.count || 0,
        "Central Balance UC Usage": roundNumber(
          row.ucCentralBalanceMetrics?.creditUsage
        ),

        "Template Credit AC Count": row.acTemplateCreditMetrics?.count || 0,
        "Template Credit AC Usage": roundNumber(
          row.acTemplateCreditMetrics?.creditUsage
        ),

        "Central Balance AC Count": row.acCentralBalanceMetrics?.count || 0,
        "Central Balance AC Usage": roundNumber(
          row.acCentralBalanceMetrics?.creditUsage
        ),

        "Template Credit SC Count": row.scTemplateCreditMetrics?.count || 0,
        "Template Credit SC Usage": roundNumber(
          row.scTemplateCreditMetrics?.creditUsage
        ),
        "Central Balance SC Count": row.scCentralBalanceMetrics?.count || 0,
        "Central Balance SC Usage": roundNumber(
          row.scCentralBalanceMetrics?.creditUsage
        ),

        "Template Credit BIC Count": row.bicTemplateCreditMetrics?.count || 0,
        "Template Credit BIC Usage": roundNumber(
          row.bicTemplateCreditMetrics?.creditUsage
        ),

        "Central Balance BIC Count": row.bicCentralBalanceMetrics?.count || 0,
        "Central Balance BIC Usage": roundNumber(
          row.bicCentralBalanceMetrics?.creditUsage
        ),

        "Template Credit UIC Count": row.uicTemplateCreditMetrics?.count || 0,
        "Template Credit UIC Usage": roundNumber(
          row.uicTemplateCreditMetrics?.creditUsage
        ),

        "Central Balance UIC Count": row.uicCentralBalanceMetrics?.count || 0,
        "Central Balance UIC Usage": roundNumber(
          row.uicCentralBalanceMetrics?.creditUsage
        ),
      }));

      const headers = Object.keys(
        data?.length > 0
          ? data[0]
          : {
              Date: new Date(),
              "Sent Chat Count": 0,
              "Delivered Chat Count": 0,
              "Read Chat Count": 0,
              "Failed Chat Count": 0,

              "Template Credit MC Count": 0,
              "Template Credit MC Usage": 0,
              "Central Balance MC Count": 0,
              "Central Balance MC Usage": 0,

              "Template Credit UC Count": 0,
              "Template Credit UC Usage": 0,
              "Central Balance UC Count": 0,
              "Central Balance UC Usage": 0,

              "Template Credit AC Count": 0,
              "Template Credit AC Usage": 0,
              "Central Balance AC Count": 0,
              "Central Balance AC Usage": 0,

              "Template Credit SC Count": 0,
              "Template Credit SC Usage": 0,
              "Central Balance SC Count": 0,
              "Central Balance SC Usage": 0,

              "Template Credit BIC Count": 0,
              "Template Credit BIC Usage": 0,
              "Central Balance BIC Count": 0,
              "Central Balance BIC Usage": 0,

              "Template Credit UIC Count": 0,
              "Template Credit UIC Usage": 0,
              "Central Balance UIC Count": 0,
              "Central Balance UIC Usage": 0,
            }
      );
      const csvRows = [];
      csvRows.push(headers.join(","));

      for (const val of data) {
        const values = Object.values(val).join(",");
        csvRows.push(values);
      }
      const csv = csvRows.join("\n");

      const downloadLink = document.createElement("a");
      const blob = new Blob(["\ufeff", csv]);
      const bloburl = window.URL.createObjectURL(blob);
      downloadLink.href = bloburl;
      downloadLink.download = "analytics.csv";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      setShowReport(false);
    } catch (error) {
      console.log("Downloading CSV failed ->", error);
    }
    setLoading(false);
  };

  return (
    <Dialog open={showReport} className={classes.dialogContainer}>
      <DialogContent>
        <Grid
          container
          spacing={2}
          style={{ paddingLeft: "20px", paddingTop: "10px" }}
        >
          <Grid xs={12} style={{ display: "flex" }}>
            <Grid
              item
              xs={8}
              style={{
                padding: "2px",
                paddingBottom: "0px",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                variant="h3"
                style={{
                  marginRight: 8,
                  minWidth: 100,
                  paddingTop: "12px",
                }}
              >
                {dialogHeading}
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <IconButton onClick={() => setShowReport(false)}>
                <Clear />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item style={{ padding: "2px 0 8px 0" }}>
            <Typography
              variant="body2"
              style={{ marginRight: 8, minWidth: 100 }}
            >
              {dialogBody}
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={3} style={{ marginRight: "15px" }}>
              <Typography>Date</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <DateFilter
                startDate={startDate}
                endDate={endDate}
                focusedInput={focusedInput}
                setDate={setDateRange}
                setFocus={setFocus}
                classes={classes}
                minDate={new Date("04/25/2023")}
              />
            </Grid>

            {(dialogHeading == "Download Partner Billing Report" ||
              dialogHeading == "Download Business Billing Report" ||
              dialogHeading == "Download Project Billing Report") && (
              <>
                <Grid item xs={12} sm={3} style={{ marginRight: "15px" }}>
                  <Typography>Reason</Typography>
                </Grid>
                <Grid item xs={12} sm={8} style={{ marginBottom: "16px" }}>
                  <Autocomplete
                    multiple
                    options={ReasonCode}
                    value={selectedReasonCodes}
                    onChange={(e, value, reason) => {
                      if (
                        reason == "select-option" ||
                        reason == "remove-option"
                      ) {
                        setSelectedReasonCodes(value);
                      }
                    }}
                    sx={{ width: 400 }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={() => resetFilter()}
            style={{ margin: 16, marginRight: 0 }}
          >
            Clear Filter
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={
              convoReportDownload
                ? dialogHeading == "Download Partner Billing Report" ||
                  dialogHeading == "Download Business Billing Report" ||
                  dialogHeading == "Download Project Billing Report"
                  ? () =>
                      getConvoReport(
                        startDate,
                        endDate,
                        "billing",
                        selectedReasonCodes
                      )
                  : () => getConvoReport(startDate, endDate, "wcc")
                : generateReport
            }
            style={{ margin: 16 }}
            disabled={
              !convoReportDownload ? !startDate || !endDate : reportLoading
            }
            endIcon={
              loading || reportLoading ? (
                <CircularProgress size={20} />
              ) : (
                <GetApp size={20} />
              )
            }
          >
            Download
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
