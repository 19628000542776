import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Checkbox,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  IconButton,
  CircularProgress,
} from "@material-ui/core";

import { withRouter } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

export const adfields = [
  {
    name: "Ad Name",
    size: 12,
    key: "adName",
  },
  {
    name: "Business Name",
    size: 12,
    key: "businessName",
  },
  {
    name: "Project Name",
    size: 12,
    key: "projectName",
  },
  {
    name: "Ad Status",
    size: 12,
    key: "adStatus",
  },
  {
    name: "Quality Ranking",
    size: 12,
    key: "qualityRanking",
  },
  {
    name: "Ad Start Date",
    size: 12,
    key: "adStartDate",
  },
  {
    name: "Ad End Date",
    size: 12,
    key: "adStopDate",
  },
  {
    name: "Daily Ad Budget",
    size: 12,
    key: "dailyBudget",
  },
  {
    name: "Impressions",
    size: 12,
    key: "impressions",
  },
  {
    name: "Reach",
    size: 12,
    key: "reach",
  },
  {
    name: "Unique Clicks",
    size: 12,
    key: "uniqueClicks",
  },
  {
    name: "Clicks",
    size: 12,
    key: "clicks",
  },
  {
    name: "Total Spend",
    size: 12,
    key: "totalBudget",
  },
  {
    name: "Cost Per Click",
    size: 12,
    key: "costPerClick",
  },
  {
    name: "Messages",
    size: 12,
    key: "totalMessages",
  },
  {
    name: "New messaging contacts",
    size: 12,
    key: "messagingFirstReply",
  },
  {
    name: "Messaging conversations started",
    size: 12,
    key: "totalMessagingConnection",
  },
  {
    name: "Engagement Ranking",
    size: 12,
    key: "engagementRateRanking",
  },
];

export const adAccountFields = [
  {
    name: "Business Name",
    size: 12,
    key: "businessName",
  },
  {
    name: "Project Name",
    size: 12,
    key: "projectName",
  },
  {
    name: "Connection Stage",
    size: 12,
    key: "connectionStage",
  },
  {
    name: "Active Ads",
    size: 12,
    key: "activeAds",
  },
  {
    name: "Cost per lead",
    size: 12,
    key: "costPerLead",
  },
  {
    name: "Number of leads",
    size: 12,
    key: "numberOfLeads",
  },
  {
    name: "Click Through Rate",
    size: 12,
    key: "clickThroughRate",
  },
  // {
  //   name: "Today's spend",
  //   size: 12,
  //   key: "todaySpend",
  // },
  {
    name: "Total spend",
    size: 12,
    key: "totalSpend",
  },
];

class AdsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  listScrolled = (event) => {
    if (!this.offsetPosition) {
      this.offsetPosition = this.rootEle.current.offsetTop;
    }
    const container = event.currentTarget;
    const currentPos = container.scrollTop;
    this.setState({
      sticky: currentPos > this.offsetPosition,
    });
  };

  render() {
    const { classes, ads, adAccounts, isLoading, page } = this.props;

    const fields = page == "Project" ? adAccountFields : adfields;

    const data = page == "Project" ? ads : adAccounts;

    return (
      <>
        <div className={classes.root}>
          <Grid container>
            <Grid container item xs={12} md={12} lg={12}>
              <Grid container>
                <Box
                  className={classes.tableWrapper}
                  mb={{ xs: 2, md: 4 }}
                  mx={{ xs: 2, md: 0 }}
                >
                  <Grid item container className={classes.tableContainer}>
                    <Grid
                      item
                      xs={12}
                      container
                      className={classes.tableHeader}
                    >
                      {fields.map((e) => (
                        <Grid
                          item
                          xs={e.size}
                          key={e.key}
                          style={{
                            width: "20%",
                          }}
                        >
                          <Box py={2} pl={2}>
                            <Typography variant="h5" color="primary">
                              {e.name}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                    {ads.map((e) => (
                      <Grid
                        item
                        xs={12}
                        container
                        onScroll={this.listScrolled}
                        className={
                          true ? classes.tableItemNoHover : classes.tableItem
                        }
                      >
                        {fields.map((field) => (
                          <Grid
                            item
                            xs={field.size}
                            style={{
                              width: "20%",
                              overflow: "hidden",
                            }}
                          >
                            <Cell
                              classes={classes}
                              item={e}
                              field={
                                this.props.isLoading ? "Loading" : field.key
                              }
                              allAds={ads}
                              allAdAccounts={adAccounts}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

function Cell({ classes, item, field, allAds, allAdAccounts }) {
  switch (field) {
    case "activeAds":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field] || 0}</Typography>
          </Box>
        </Grid>
      );
      break;
    case "adStartDate":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">
              {getFormattedDate(item[field])}
            </Typography>
          </Box>
        </Grid>
      );

      break;
    case "adStopDate":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">
              {getFormattedDate(item[field])}
            </Typography>
          </Box>
        </Grid>
      );

      break;
    case "Loading":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            {Array.from(Array(20).keys()).map((i) => {
              return (
                <div key={i} className={classes.tableItem1}>
                  <Skeleton className={classes.skeleton} />
                </div>
              );
            })}
          </Box>
        </Grid>
      );
      break;

    case "numberOfLeads":
      const nol = item?.totalLeadsGenerated || "0";

      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{nol}</Typography>
          </Box>
        </Grid>
      );

      break;
    default:
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field]}</Typography>
          </Box>
        </Grid>
      );
  }
}

export function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}
const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // marginBottom: "5em",
    // paddingBottom: "80px"
  },
  tableWrapper: {
    overflowX: "auto",
    width: "100%",
  },

  tableContainer: {
    marginTop: "1.5em",
    minWidth: 1800,
  },

  tableItem: {
    background: "white",
    marginBottom: "1em",
    padding: 4,
    flexWrap: "nowrap",
    borderRadius: 8,
    cursor: "pointer",
    "&:hover": {
      background: "#efefef",
    },
  },
  tableItemNoHover: {
    background: "white",
    marginBottom: "1em",
    padding: 4,
    flexWrap: "nowrap",
    borderRadius: 8,
  },
  tableItem1: {
    marginBottom: "1em",
    padding: 4,
    flexWrap: "nowrap",
    borderRadius: 8,
  },

  tableHeader: {
    background: "white",
    padding: 4,
    borderRadius: 8,
    marginBottom: "1em",
    flexWrap: "nowrap",
  },
  row: {
    background: "white",
    display: "flex",
  },
  alterRow: {
    background: "rgb(246,246,246)",
  },
  dataRow: {
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "rgb(70 20 134 / 15%)",
    },
  },
  topbar: {
    background: "rgb(252,252,252)",
    position: "sticky",
    top: "0",
    zIndex: "100",
  },
  column: {
    // width: "4400px",
    fontWeight: "normal",
    height: "50px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: "border-box",
  },
});

export default withStyles(styles)(AdsTable);
