import React from "react";
import {
  PersonOutlined,
  DashboardOutlined,
  Business,
  AccountTree,
  FlashOn,
  AddBox,
  GroupOutlined,
  GroupWork,
  ListAlt,
  Code,
  SettingsOutlined,
  CropSquareRounded as CurrencyRupeeIcon,
  Build,
  SecurityOutlined as SecurityIcon,
  AddBoxOutlined,
  TuneOutlined,
  Facebook,
} from "@material-ui/icons";

//CurrencyRupeeIcon
import AgentProfilePage from "../../../pages/AgentProfile/AgentProfilePage";
import DashboardPage from "../../../pages/Dashboard/DashboardPage";
import OrdersPage from "../../../pages/Orders/OrdersPage";
import AssistantsRoute from "../../../pages/Assistants/AssistantsRoute";
import TemplateRoute from "../../../pages/TemplateMsg/TemplateRoute";
import ConfigPage from "../../../pages/Config/ConfigPage";
import ClientRoute from "../../../pages/Clients/ClientRoute";
import BillingRoute from "../../../pages/BillingProfile/BillingRoute";
import PreviewPage from "../../../pages/Preview/PreviewPage";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import BlurLinearSharpIcon from "@material-ui/icons/BlurLinearSharp";
import BusinessCenterSharpIcon from "@material-ui/icons/BusinessCenterSharp";
import BorderAllSharpIcon from "@material-ui/icons/BorderAllSharp";
import PlanDetails from "../../../pages/PlanDetails/PlanDetails";
import PlanFamilyPage from "../../../pages/Manage/PlanFamilyPage";
import ManageLayout from "../ManageLayout";
import OnboardingPage from "../../../pages/Manage/OnboardingPage";
import AgentsPage from "../../../pages/Manage/AgentsPage";
import ProfilePage from "../../../pages/Manage/ProfilePage";
import SSOPage from "../../../pages/Manage/SSOPage";
import addonsPage from "../../../pages/Manage/addonsPage";
import DefaultsPage from "../../../pages/Manage/DefaultsPage";
import AgentRoles from "../../../pages/Manage/AgentRoles";
import PartnerAgentsPage from "../../../pages/Manage/PartnerAgents/PartnerAgentsPage";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import MetaAdsLayout from "../MetaAdsLayout";
import MetaAds from "../../../pages/MetaAds/MetaAds";

const viewModeRoutes = [
  {
    name: "Dashboard",
    to: "/",
    component: DashboardPage,
    icon: <BorderAllSharpIcon style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 1,
    exact: true,
  },
  {
    name: "Businesses",
    to: "/businesses",
    component: ClientRoute,
    icon: <BusinessCenterSharpIcon style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 0,
  },
  {
    name: "Projects",
    to: "/assistants",
    component: AssistantsRoute,
    icon: <BlurLinearSharpIcon style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 0,
  },
];

const topRoutes = [
  {
    name: "Dashboard",
    to: "/",
    component: DashboardPage,
    icon: <BorderAllSharpIcon style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 1,
    exact: true,
  },
  {
    name: "Businesses",
    to: "/businesses",
    component: ClientRoute,
    icon: <BusinessCenterSharpIcon style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 0,
  },
  {
    name: "Projects",
    to: "/assistants",
    component: AssistantsRoute,
    icon: <BlurLinearSharpIcon style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 0,
  },
  {
    name: "Meta Ads",
    to: "/meta-ads",
    component: MetaAdsLayout,
    icon: <Facebook style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 0,
  },
  // {
  //   name: "Plans",
  //   to: "/plans",
  //   component: ChangePlans,
  //   icon: <AccountTree style={{ fontSize: "20px" }} />,
  //   power: 0,
  //   manage: 0,
  // },
  // {
  //   name: "Templates",
  //   to: "/template-messages",
  //   component: TemplateRoute,
  //   icon: <FlashOn style={{ fontSize: "20px" }} />,
  //   power: 0,
  //   manage: 0,
  // },
  // {
  //   name: "Support",
  //   to: "/orders",
  //   component: OrdersPage,
  //   icon: <GroupWork style={{ fontSize: "20px" }} />,
  //   power: 0,
  //   manage: 0,
  // },
  // {
  //   name: "Config",
  //   to: "/config",
  //   component: ConfigPage,
  //   icon: <Code style={{ fontSize: "20px" }} />,
  //   power: 0,
  //   manage: 0,
  // },
  {
    name: "Plan Details",
    to: "/plan-details",
    component: PlanDetails,
    icon: <ListAlt style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 0,
    excludedTypes: ["DIRECT"],
  },
  {
    name: "Billing",
    to: "/billings",
    component: BillingRoute,
    icon: <AttachMoneyIcon style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 1,
  },
  {
    to: "/preview",
    component: PreviewPage,
    icon: <Code style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 0,
  },
  {
    name: "Manage",
    to: "/manage",
    component: ManageLayout,
    icon: <SettingsOutlined style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 0,
    excludedTypes: ["DIRECT"],
  },
];

const renderManageRoute = [
  {
    name: "Configure Plan",
    to: "/config",
    component: PlanFamilyPage,
    icon: <Build style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 0,
    excludedTypes: ["DIRECT"],
  },
  {
    name: "Onboarding",
    to: "/onboarding",
    component: OnboardingPage,
    icon: <AddBox style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 0,
    excludedTypes: ["DIRECT"],
  },
  {
    name: "Agents",
    to: "/agents",
    component: AgentsPage,
    icon: <RecentActorsIcon style={{ fontSize: "20px" }} />,
    power: 1,
    manage: 1,
    integrations: 0,
  },
  {
    name: "Single Sign On",
    to: "/sso",
    component: SSOPage,
    icon: <SecurityIcon style={{ fontSize: "20px" }} />,
    power: 1,
    manage: 1,
    integrations: 0,
  },
  {
    name: "Profile",
    to: "/profiles",
    component: ProfilePage,
    icon: <GroupOutlined style={{ fontSize: "20px" }} />,
    power: 1,
    manage: 1,
    integrations: 0,
  },
  {
    name: "Manage Addons",
    to: "/manage-addons",
    component: addonsPage,
    icon: <AddBoxOutlined style={{ fontSize: "20px" }} />,
    power: 1,
    manage: 1,
    integrations: 0,
  },
  {
    name: "Defaults",
    to: "/defaults",
    component: DefaultsPage,
    icon: <TuneOutlined style={{ fontSize: "20px" }} />,
    power: 1,
    manage: 1,
    integrations: 0,
  },
  {
    name: "Roles",
    to: "/roles",
    component: AgentRoles,
    icon: <AllInboxIcon style={{ fontSize: "20px" }} />,
    power: 1,
    manage: 1,
    integrations: 0,
  },
  {
    name: "Team",
    to: "/team",
    component: PartnerAgentsPage,
    icon: <AssignmentIndIcon style={{ fontSize: "20px" }} />,
    power: 1,
    manage: 1,
    integrations: 0,
  },
];

const renderMetaAdsRoute = [
  {
    name: "Projects",
    to: "/project",
    component: MetaAds,
    icon: <BusinessCenterSharpIcon style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 0,
  },
  {
    name: "Ads",
    to: "/ads",
    component: MetaAds,
    icon: <BlurLinearSharpIcon style={{ fontSize: "20px" }} />,
    power: 0,
    manage: 0,
  },
];

const bottomRoutes = [
  {
    name: "Profile",
    to: "/agentProfile",
    component: AgentProfilePage,
    icon: <PersonOutlined />,
  },
];

export {
  viewModeRoutes,
  topRoutes,
  bottomRoutes,
  renderManageRoute,
  renderMetaAdsRoute,
};
