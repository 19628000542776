import React, { Component } from "react";
import {
  Grid,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import { URL } from "../../../config/config";
import { modifyPlan } from "../../../store/planFamilyState";
import { connect } from "react-redux";
class PopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertType: "",
      alertMsg: "",
      partnerPlans: { ...this.props.partnerPlans },
      currency: "",
    };
  }

  onSnackbarClose = () => {
    this.setState({
      alertType: "",
      alertMsg: "",
    });
  };

  savePlan = () => {
    const plans = this.state.partnerPlans;
    const activeId = this.props.activeId;
    const payload = {
      plans,
      planId: activeId,
    };
    axios
      .patch(URL + "/api/update-partner-plan-families", payload)
      .then((response) => {
        this.props.triggerAlert({
          alertType: "success",
          alertMsg: "Plan updated successfully",
        });
        this.props.toggleDialog();
        this.props.modifyPlan(payload);
      })
      .catch((error) => {
        this.setState({
          alertType: "error",
          alertMsg:
            error?.response?.data?.message || "something went wrong, try again",
        });
      });
  };

  render() {
    const {
      classes,
      aisensyPlans,
      convertPrice,
      openDialog,
      toggleDialog,
      currency,
      disableFields,
    } = this.props;

    const { partnerPlans, margins } = this.state;

    return Object.keys(partnerPlans).length <= 0 ? (
      <CircularProgress />
    ) : (
      <>
        <Dialog open={openDialog} closeDialog={toggleDialog} maxWidth={"sm"}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle className={classes.dialogTitle}>
              <Typography variant="h3">Plan Family Details</Typography>
            </DialogTitle>
            {/* <Clear className={classes.icon} onClick={toggleDialog} /> */}
            {/* <Tabs
              value={tab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              TabIndicatorProps={{
                className: classes.tabIndicator,
              }}
            >
              <Tab label="₹INR" value="INR" className={classes.tabHeader} />
              <Tab label="$USD" value="USD" className={classes.tabHeader} />
            </Tabs> */}
          </Box>
          <Box
            style={{ padding: "24px 16px 16px 24px" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <DialogContent>
              <Grid
                container
                alignItems="center"
                className={classes.content}
                spacing={0}
              >
                <Grid container>
                  <Grid item md={3}></Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <Typography
                      required
                      className={classes.typo}
                      variant="h5"
                      align="center"
                    >
                      {Object.keys(this.props.tenantDetails).length
                        ? this.props.tenantDetails.name
                        : "AiSensy"}{" "}
                      Prices
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <Typography
                      required
                      className={classes.typo}
                      variant="h5"
                      align="center"
                    >
                      Margin
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <Typography
                      required
                      className={classes.typo}
                      variant="h5"
                      align="center"
                    >
                      Your Platform Prices
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <Typography
                      required
                      className={classes.typo}
                      variant="h5"
                      align="center"
                    >
                      Basic Monthly
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <TextField
                      style={{
                        width: "100%",
                        padding: "3px 0px",
                        marginRight: 10,
                      }}
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      onChange={(e) => {
                        this.setState({ contactName: e.target.value });
                      }}
                      className={classes.input}
                      error={this.state.displayNameError}
                      disabled="true"
                      margin="dense"
                      value={
                        aisensyPlans.BASIC_MONTHLY &&
                        aisensyPlans.BASIC_MONTHLY[currency] / convertPrice
                      }
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <TextField
                      style={{
                        width: "100%",
                        padding: "3px 0px",
                        marginRight: 10,
                      }}
                      required
                      type="Number"
                      variant="outlined"
                      disabled={disableFields}
                      placeholder="0"
                      onChange={(e) => {
                        let value = Number(e.target.value) * 100000 ?? 0;
                        if (value < 0) value = 0;
                        const BASIC_MONTHLY = partnerPlans.BASIC_MONTHLY;
                        BASIC_MONTHLY[currency] =
                          value + aisensyPlans.BASIC_MONTHLY[currency];
                        this.setState({
                          partnerPlans: {
                            ...partnerPlans,
                            BASIC_MONTHLY,
                          },
                          margins: {
                            ...margins,
                            BASIC_MONTHLY: e.target.value,
                          },
                        });
                      }}
                      className={classes.input}
                      error={this.state.displayNameError}
                      margin="dense"
                      // value={margins.BASIC_MONTHLY}
                      //value={margins.BASIC_MONTHLY}
                      defaultValue={
                        aisensyPlans.BASIC_MONTHLY &&
                        (partnerPlans.BASIC_MONTHLY[currency] -
                          aisensyPlans.BASIC_MONTHLY[currency]) /
                          convertPrice
                      }
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                        inputProps: { min: 0 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <TextField
                      style={{ width: "100%", padding: "3px 0px" }}
                      required
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      disabled="true"
                      onChange={(e) => {
                        this.setState({ contactName: e.target.value });
                      }}
                      className={classes.input}
                      error={this.state.displayNameError}
                      margin="dense"
                      value={
                        partnerPlans.BASIC_MONTHLY &&
                        partnerPlans.BASIC_MONTHLY[currency] / convertPrice
                      }
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                      }}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container>
                <Grid item xs={12} md={3} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    Basic Quarterly
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{
                      width: "100%",
                      padding: "3px 0px",
                      marginRight: 10,
                    }}
                    required
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    //   onChange={(e) => {
                    //     this.setState({ contactName: e.target.value });
                    //   }}
                    className={classes.input}
                    error={this.state.displayNameError}
                    disabled="true"
                    margin="dense"
                    name="renderProPrice"
                    value={
                      aisensyPlans.basic_quarterly &&
                      aisensyPlans.basic_quarterly[currency] / convertPrice
                    }
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{
                      width: "100%",
                      padding: "3px 0px",
                      marginRight: 10,
                    }}
                    required
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    onChange={(e) => {
                      const value = Number(e.target.value) * 100000 ?? 0;
                      const basic_quarterly = partnerPlans.basic_quarterly;
                      basic_quarterly[currency] =
                        value + aisensyPlans.basic_quarterly[currency];
                      this.setState({
                        partnerPlans: {
                          ...partnerPlans,
                          basic_quarterly,
                        },
                      });
                    }}
                    className={classes.input}
                    error={this.state.displayNameError}
                    margin="dense"
                    name="proMargin"
                    value={
                      aisensyPlans.basic_quarterly &&
                      partnerPlans.basic_quarterly[currency] / 100000 -
                        aisensyPlans.basic_quarterly[currency] / 100000
                    }
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                      inputProps: { min: 0 },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{ width: "100%", padding: "3px 0px" }}
                    required
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    disabled="true"
                    onChange={(e) => {
                      this.setState({ contactName: e.target.value });
                    }}
                    className={classes.input}
                    error={this.state.displayNameError}
                    margin="dense"
                    name="proPlatform"
                    value={
                      partnerPlans.basic_quarterly &&
                      partnerPlans.basic_quarterly[currency] / convertPrice
                    }
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                    }}
                  />
                </Grid>
              </Grid> */}
                <Grid container>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <Typography
                      required
                      className={classes.typo}
                      variant="h5"
                      align="center"
                    >
                      Basic Yearly
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <TextField
                      style={{
                        width: "100%",
                        padding: "3px 0px",
                        marginRight: 10,
                      }}
                      required
                      type="Number"
                      variant="outlined"
                      disabled="true"
                      placeholder="0"
                      onChange={(e) => {
                        this.setState({ contactName: e.target.value });
                      }}
                      className={classes.input}
                      error={this.state.displayNameError}
                      margin="dense"
                      value={
                        aisensyPlans.BASIC_YEARLY &&
                        aisensyPlans.BASIC_YEARLY[currency] / convertPrice
                      }
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <TextField
                      style={{
                        width: "100%",
                        padding: "3px 0px",
                        marginRight: 10,
                      }}
                      required
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      disabled={disableFields}
                      onChange={(e) => {
                        let value = Number(e.target.value) * 100000 ?? 0;
                        if (value < 0) value = 0;
                        const BASIC_YEARLY = partnerPlans.BASIC_YEARLY;
                        BASIC_YEARLY[currency] =
                          value + aisensyPlans.BASIC_YEARLY[currency];
                        this.setState({
                          partnerPlans: {
                            ...partnerPlans,
                            BASIC_YEARLY,
                          },
                        });
                      }}
                      className={classes.input}
                      error={this.state.displayNameError}
                      defaultValue={
                        aisensyPlans.BASIC_YEARLY &&
                        (partnerPlans.BASIC_YEARLY[currency] -
                          aisensyPlans.BASIC_YEARLY[currency]) /
                          100000
                      }
                      margin="dense"
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                        inputProps: { min: 0 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <TextField
                      style={{ width: "100%", padding: "3px 0px" }}
                      required
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      disabled="true"
                      onChange={(e) => {
                        this.setState({ contactName: e.target.value });
                      }}
                      className={classes.input}
                      error={this.state.displayNameError}
                      margin="dense"
                      value={
                        partnerPlans.BASIC_YEARLY &&
                        partnerPlans.BASIC_YEARLY[currency] / convertPrice
                      }
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <Typography
                      required
                      className={classes.typo}
                      variant="h5"
                      align="center"
                    >
                      Pro Monthly
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <TextField
                      style={{
                        width: "100%",
                        padding: "3px 0px",
                        marginRight: 10,
                      }}
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      onChange={(e) => {
                        this.setState({ contactName: e.target.value });
                      }}
                      className={classes.input}
                      error={this.state.displayNameError}
                      disabled="true"
                      margin="dense"
                      value={
                        aisensyPlans.PRO_MONTHLY &&
                        aisensyPlans.PRO_MONTHLY[currency] / convertPrice
                      }
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <TextField
                      style={{
                        width: "100%",
                        padding: "3px 0px",
                        marginRight: 10,
                      }}
                      required
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      disabled={disableFields}
                      onChange={(e) => {
                        let value = Number(e.target.value) * 100000 ?? 0;
                        if (value < 0) value = 0;
                        const PRO_MONTHLY = partnerPlans.PRO_MONTHLY;
                        PRO_MONTHLY[currency] =
                          value + aisensyPlans.PRO_MONTHLY[currency];
                        this.setState({
                          partnerPlans: {
                            ...partnerPlans,
                            PRO_MONTHLY,
                          },
                        });
                      }}
                      className={classes.input}
                      error={this.state.displayNameError}
                      margin="dense"
                      defaultValue={
                        aisensyPlans.PRO_MONTHLY &&
                        (partnerPlans.PRO_MONTHLY[currency] -
                          aisensyPlans.PRO_MONTHLY[currency]) /
                          convertPrice
                      }
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                        inputProps: { min: 0 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <TextField
                      style={{ width: "100%", padding: "3px 0px" }}
                      required
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      disabled="true"
                      onChange={(e) => {
                        this.setState({ contactName: e.target.value });
                      }}
                      className={classes.input}
                      error={this.state.displayNameError}
                      margin="dense"
                      value={
                        partnerPlans.PRO_MONTHLY &&
                        partnerPlans.PRO_MONTHLY[currency] / convertPrice
                      }
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                      }}
                    />
                  </Grid>
                </Grid>
                {/* <Grid container>
                <Grid item xs={12} md={3} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    Pro Quarterly
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{
                      width: "100%",
                      padding: "3px 0px",
                      marginRight: 10,
                    }}
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    onChange={(e) => {
                      this.setState({ contactName: e.target.value });
                    }}
                    className={classes.input}
                    error={this.state.displayNameError}
                    disabled="true"
                    margin="dense"
                    name="renderBasicPrice"
                    value={
                      aisensyPlans.pro_quarterly &&
                      aisensyPlans.pro_quarterly[currency] / convertPrice
                    }
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{
                      width: "100%",
                      padding: "3px 0px",
                      marginRight: 10,
                    }}
                    required
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    onChange={(e) => {
                      const value = Number(e.target.value) * 100000 ?? 0;
                      const pro_quarterly = partnerPlans.pro_quarterly;
                      pro_quarterly[currency] =
                        value + aisensyPlans.pro_quarterly[currency];
                      this.setState({
                        partnerPlans: {
                          ...partnerPlans,
                          pro_quarterly,
                        },
                      });
                    }}
                    className={classes.input}
                    error={this.state.displayNameError}
                    margin="dense"
                    name="basicMargin"
                    value={
                      aisensyPlans.pro_quarterly &&
                      partnerPlans.pro_quarterly[currency] / 100000 -
                        aisensyPlans.pro_quarterly[currency] / 100000
                    }
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                      inputProps: { min: 0 },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{ width: "100%", padding: "3px 0px" }}
                    required
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    disabled="true"
                    onChange={(e) => {
                      this.setState({ contactName: e.target.value });
                    }}
                    className={classes.input}
                    error={this.state.displayNameError}
                    margin="dense"
                    name="basicPlatform"
                    value={
                      partnerPlans.pro_quarterly &&
                      partnerPlans.pro_quarterly[currency] / convertPrice
                    }
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                    }}
                  />
                </Grid>
              </Grid> */}
                <Grid container>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <Typography
                      required
                      className={classes.typo}
                      variant="h5"
                      align="center"
                    >
                      Pro Yearly
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <TextField
                      style={{
                        width: "100%",
                        padding: "3px 0px",
                        marginRight: 10,
                      }}
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      onChange={(e) => {
                        this.setState({ contactName: e.target.value });
                      }}
                      className={classes.input}
                      error={this.state.displayNameError}
                      disabled="true"
                      margin="dense"
                      value={
                        aisensyPlans.PRO_YEARLY &&
                        aisensyPlans.PRO_YEARLY[currency] / convertPrice
                      }
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <TextField
                      style={{
                        width: "100%",
                        padding: "3px 0px",
                        marginRight: 10,
                      }}
                      required
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      disabled={disableFields}
                      onChange={(e) => {
                        let value = Number(e.target.value) * 100000 ?? 0;
                        if (value < 0) value = 0;
                        const PRO_YEARLY = partnerPlans.PRO_YEARLY;
                        PRO_YEARLY[currency] =
                          value + aisensyPlans.PRO_YEARLY[currency];
                        this.setState({
                          partnerPlans: {
                            ...partnerPlans,
                            PRO_YEARLY,
                          },
                        });
                      }}
                      className={classes.input}
                      error={this.state.displayNameError}
                      margin="dense"
                      defaultValue={
                        aisensyPlans.PRO_YEARLY &&
                        (partnerPlans.PRO_YEARLY[currency] -
                          aisensyPlans.PRO_YEARLY[currency]) /
                          convertPrice
                      }
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                        inputProps: { min: 0 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} container alignItems="center">
                    <TextField
                      style={{ width: "100%", padding: "3px 0px" }}
                      required
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      disabled="true"
                      onChange={(e) => {
                        this.setState({ contactName: e.target.value });
                      }}
                      className={classes.input}
                      error={this.state.displayNameError}
                      margin="dense"
                      value={
                        partnerPlans.PRO_YEARLY &&
                        partnerPlans.PRO_YEARLY[currency] / convertPrice
                      }
                      InputProps={{
                        startAdornment: currency === "USD" ? "$" : "₹",
                      }}
                    />
                  </Grid>
                </Grid>
                {aisensyPlans.ENTERPRISE_MONTHLY && (
                  <Grid container>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <Typography
                        required
                        className={classes.typo}
                        variant="h5"
                        align="center"
                      >
                        Enterprise Monthly
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <TextField
                        style={{
                          width: "100%",
                          padding: "3px 0px",
                          marginRight: 10,
                        }}
                        type="Number"
                        variant="outlined"
                        placeholder="0"
                        onChange={(e) => {
                          this.setState({ contactName: e.target.value });
                        }}
                        className={classes.input}
                        error={this.state.displayNameError}
                        disabled="true"
                        margin="dense"
                        value={
                          (aisensyPlans.ENTERPRISE_MONTHLY &&
                            aisensyPlans.ENTERPRISE_MONTHLY[currency] /
                              convertPrice) ??
                          0
                        }
                        InputProps={{
                          startAdornment: currency === "USD" ? "$" : "₹",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <TextField
                        style={{
                          width: "100%",
                          padding: "3px 0px",
                          marginRight: 10,
                        }}
                        required
                        type="Number"
                        variant="outlined"
                        placeholder="0"
                        disabled={
                          disableFields || !aisensyPlans.ENTERPRISE_MONTHLY
                        }
                        onChange={(e) => {
                          let value = Number(e.target.value) * 100000 ?? 0;
                          if (value < 0) value = 0;
                          const ENTERPRISE_MONTHLY = {
                            ...(partnerPlans.ENTERPRISE_MONTHLY ??
                              aisensyPlans.ENTERPRISE_MONTHLY),
                          };
                          ENTERPRISE_MONTHLY[currency] =
                            value + aisensyPlans.ENTERPRISE_MONTHLY[currency];
                          this.setState({
                            partnerPlans: {
                              ...partnerPlans,
                              ENTERPRISE_MONTHLY,
                            },
                          });
                        }}
                        className={classes.input}
                        error={this.state.displayNameError}
                        margin="dense"
                        defaultValue={
                          aisensyPlans?.ENTERPRISE_MONTHLY &&
                          this.state.partnerPlans?.ENTERPRISE_MONTHLY &&
                          (this.state.partnerPlans?.ENTERPRISE_MONTHLY[
                            currency
                          ] -
                            aisensyPlans?.ENTERPRISE_MONTHLY[currency]) /
                            100000
                        }
                        InputProps={{
                          startAdornment: currency === "USD" ? "$" : "₹",
                          inputProps: { min: 0 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <TextField
                        style={{ width: "100%", padding: "3px 0px" }}
                        required
                        type="Number"
                        variant="outlined"
                        placeholder="0"
                        disabled="true"
                        onChange={(e) => {
                          this.setState({ contactName: e.target.value });
                        }}
                        className={classes.input}
                        error={this.state.displayNameError}
                        margin="dense"
                        value={
                          this.state.partnerPlans.ENTERPRISE_MONTHLY &&
                          this.state.partnerPlans.ENTERPRISE_MONTHLY[currency] /
                            convertPrice
                        }
                        InputProps={{
                          startAdornment: currency === "USD" ? "$" : "₹",
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
                {aisensyPlans.FREE_FOREVER && (
                  <Grid container>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <Typography
                        required
                        className={classes.typo}
                        variant="h5"
                        align="center"
                      >
                        Starter Plan
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <TextField
                        style={{
                          width: "100%",
                          padding: "3px 0px",
                          marginRight: 10,
                        }}
                        type="Number"
                        variant="outlined"
                        placeholder="0"
                        onChange={(e) => {
                          this.setState({ contactName: e.target.value });
                        }}
                        className={classes.input}
                        error={this.state.displayNameError}
                        disabled="true"
                        margin="dense"
                        value={
                          aisensyPlans.FREE_FOREVER &&
                          aisensyPlans.FREE_FOREVER[currency] / convertPrice
                        }
                        InputProps={{
                          startAdornment: currency === "USD" ? "$" : "₹",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <TextField
                        style={{
                          width: "100%",
                          padding: "3px 0px",
                          marginRight: 10,
                        }}
                        required
                        type="Number"
                        variant="outlined"
                        placeholder="0"
                        disabled={disableFields}
                        onChange={(e) => {
                          let value = Number(e.target.value) * 100000 ?? 0;
                          if (value < 0) value = 0;
                          const FREE_FOREVER =
                            partnerPlans.FREE_FOREVER ||
                            aisensyPlans.FREE_FOREVER;
                          FREE_FOREVER[currency] =
                            value + aisensyPlans.FREE_FOREVER[currency];
                          this.setState({
                            partnerPlans: {
                              ...partnerPlans,
                              FREE_FOREVER,
                            },
                          });
                        }}
                        className={classes.input}
                        error={this.state.displayNameError}
                        margin="dense"
                        defaultValue={
                          aisensyPlans.FREE_FOREVER &&
                          partnerPlans.FREE_FOREVER &&
                          (partnerPlans.FREE_FOREVER[currency] -
                            aisensyPlans.FREE_FOREVER[currency]) /
                            convertPrice
                        }
                        InputProps={{
                          startAdornment: currency === "USD" ? "$" : "₹",
                          inputProps: { min: 0 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <TextField
                        style={{ width: "100%", padding: "3px 0px" }}
                        required
                        type="Number"
                        variant="outlined"
                        placeholder="0"
                        disabled="true"
                        onChange={(e) => {
                          this.setState({ contactName: e.target.value });
                        }}
                        className={classes.input}
                        error={this.state.displayNameError}
                        margin="dense"
                        value={
                          partnerPlans.FREE_FOREVER &&
                          partnerPlans.FREE_FOREVER[currency] / convertPrice
                        }
                        InputProps={{
                          startAdornment: currency === "USD" ? "$" : "₹",
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
                {/* <Grid container>
                <Grid item xs={12} md={3} container alignItems="center">
                  <Typography
                    required
                    className={classes.typo}
                    variant="h5"
                    align="center"
                  >
                    Enterprise Quarterly
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{
                      width: "100%",
                      padding: "3px 0px",
                      marginRight: 10,
                    }}
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    onChange={(e) => {
                      this.setState({ contactName: e.target.value });
                    }}
                    className={classes.input}
                    error={this.state.displayNameError}
                    disabled="true"
                    margin="dense"
                    name="renderBasicPrice"
                    value={
                      aisensyPlans.enterprise_quarterly &&
                      aisensyPlans.enterprise_quarterly[currency] / convertPrice
                    }
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{
                      width: "100%",
                      padding: "3px 0px",
                      marginRight: 10,
                    }}
                    required
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    disabled={!aisensyPlans.enterprise_quarterly}
                    onChange={(e) => {
                      const value = Number(e.target.value) * 100000 ?? 0;
                      const enterprise_quarterly =
                        partnerPlans.enterprise_quarterly;
                      enterprise_quarterly[currency] =
                        value + aisensyPlans.enterprise_quarterly[currency];
                      this.setState({
                        partnerPlans: {
                          ...partnerPlans,
                          enterprise_quarterly,
                        },
                      });
                    }}
                    className={classes.input}
                    error={this.state.displayNameError}
                    margin="dense"
                    name="basicMargin"
                    value={
                      aisensyPlans.enterprise_quarterly &&
                      partnerPlans.enterprise_quarterly[currency] / 100000 -
                        aisensyPlans.enterprise_quarterly[currency] / 100000
                    }
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                      inputProps: { min: 0 },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3} container alignItems="center">
                  <TextField
                    style={{ width: "100%", padding: "3px 0px" }}
                    required
                    type="Number"
                    variant="outlined"
                    placeholder="0"
                    disabled="true"
                    onChange={(e) => {
                      this.setState({ contactName: e.target.value });
                    }}
                    className={classes.input}
                    error={this.state.displayNameError}
                    margin="dense"
                    name="basicPlatform"
                    value={
                      partnerPlans.enterprise_quarterly &&
                      partnerPlans.enterprise_quarterly[currency] / convertPrice
                    }
                    InputProps={{
                      startAdornment: currency === "USD" ? "$" : "₹",
                    }}
                  />
                </Grid>
              </Grid> */}
                {aisensyPlans?.ENTERPRISE_YEARLY && (
                  <Grid container>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <Typography
                        required
                        className={classes.typo}
                        variant="h5"
                        align="center"
                      >
                        Enterprise Yearly
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <TextField
                        style={{
                          width: "100%",
                          padding: "3px 0px",
                          marginRight: 10,
                        }}
                        type="Number"
                        variant="outlined"
                        placeholder="0"
                        onChange={(e) => {
                          this.setState({ contactName: e.target.value });
                        }}
                        className={classes.input}
                        error={this.state.displayNameError}
                        disabled="true"
                        margin="dense"
                        value={
                          (aisensyPlans?.ENTERPRISE_YEARLY &&
                            aisensyPlans?.ENTERPRISE_YEARLY[currency] /
                              convertPrice) ??
                          0
                        }
                        InputProps={{
                          startAdornment: currency === "USD" ? "$" : "₹",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <TextField
                        style={{
                          width: "100%",
                          padding: "3px 0px",
                          marginRight: 10,
                        }}
                        required
                        type="Number"
                        disabled={
                          disableFields || !aisensyPlans?.ENTERPRISE_YEARLY
                        }
                        variant="outlined"
                        placeholder="0"
                        onChange={(e) => {
                          let value = Number(e.target.value) * 100000 ?? 0;
                          if (value < 0) value = 0;
                          const ENTERPRISE_YEARLY = {
                            ...(partnerPlans?.ENTERPRISE_YEARLY ??
                              aisensyPlans.ENTERPRISE_YEARLY),
                          };
                          ENTERPRISE_YEARLY[currency] =
                            value + aisensyPlans?.ENTERPRISE_YEARLY[currency];
                          this.setState({
                            partnerPlans: {
                              ...partnerPlans,
                              ENTERPRISE_YEARLY,
                            },
                          });
                        }}
                        className={classes.input}
                        error={this.state.displayNameError}
                        margin="dense"
                        defaultValue={
                          this.state.partnerPlans?.ENTERPRISE_YEARLY &&
                          this.state.partnerPlans?.ENTERPRISE_YEARLY &&
                          (this.state.partnerPlans?.ENTERPRISE_YEARLY[
                            currency
                          ] -
                            aisensyPlans?.ENTERPRISE_YEARLY[currency]) /
                            100000
                        }
                        InputProps={{
                          startAdornment: currency === "USD" ? "$" : "₹",
                          inputProps: { min: 0 },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <TextField
                        style={{ width: "100%", padding: "3px 0px" }}
                        required
                        type="Number"
                        variant="outlined"
                        placeholder="0"
                        disabled="true"
                        onChange={(e) => {
                          this.setState({ contactName: e.target.value });
                        }}
                        className={classes.input}
                        error={this.state.displayNameError}
                        margin="dense"
                        value={
                          this.state.partnerPlans?.ENTERPRISE_YEARLY &&
                          this.state.partnerPlans?.ENTERPRISE_YEARLY[currency] /
                            convertPrice
                        }
                        InputProps={{
                          startAdornment: currency === "USD" ? "$" : "₹",
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions style={{ padding: "24px 16px 8px 0px" }}>
              <Button
                variant={!this.props.disableFields ? "outlined" : "contained"}
                size="medium"
                color="primary"
                onClick={() => {
                  toggleDialog();
                }}
              >
                {this.props.disableFields ? "Close" : "Cancel"}
              </Button>
              {!this.props.disableFields && (
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={this.savePlan}
                >
                  Save
                </Button>
              )}
            </DialogActions>
          </Box>
        </Dialog>
        <Snackbar
          open={!!this.state.alertMsg}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
          className={classes.snackbar}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType} onClose={this.onSnackbarClose}>
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

const PopupDetails = connect(
  (state) => ({
    partnerPlanFamily: state.planFamilies.allPlanFamilies,
    tenantDetails: state.tenant.tenant,
  }),
  {
    modifyPlan,
  }
)(PopUp);

export default PopupDetails;
