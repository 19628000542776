export const URL =
  process.env.REACT_APP_PARTNER_REST_API_URL ||
  (process.env.REACT_APP_MODE === "production"
    ? "https://backend.aisensy.com/partner/t1"
    : process.env.REACT_APP_MODE === "staging"
    ? "https://backend.aisensy.com/stg/partner/t1"
    : "http://localhost:5099/partner/t1");

export const partnerURL =
  process.env.REACT_APP_PARTNER_APIS_URL ||
  (process.env.REACT_APP_MODE === "production"
    ? "https://apis.aisensy.com/partner-apis/v1"
    : process.env.REACT_APP_MODE === "staging"
    ? "https://backend.aisensy.com/stg/client/t1/partner-apis/v1"
    : "http://localhost:5001/client/t1/partner-apis/v1");

export const metaAdsPageAccess = [
  "66fe33ed494c320b70b659e6",
  "66a338e89bb85d3b146b8b3b",
];

export const projectURL =
  process.env.REACT_APP_PROJECT_APIS_URL ||
  (process.env.REACT_APP_MODE === "production"
    ? "https://apis.aisensy.com/project-apis/v1"
    : process.env.REACT_APP_MODE === "staging"
    ? "https://backend.aisensy.com/stg/client/t1/project-apis/v1"
    : "http://localhost:5001/client/t1/project-apis/v1");

export const APIURL =
  process.env.REACT_APP_WHATSAPP_REST_API_URL ||
  (process.env.REACT_APP_MODE === "production"
    ? "https://backend.aisensy.com/client/t1"
    : process.env.REACT_APP_MODE === "staging"
    ? "https://backend.aisensy.com/stg/client/t1"
    : "http://localhost:5001/client/t1");

export const TENANT_ID = process.env.REACT_APP_TENANT_ID;

export const META_ADS_PARTNER = [
  "63fdbd4b136c0b191dcaafa7",
  "64760382a2bc4b049cf117b5",
  "650459915b15380bcf2b9a9a",
  "645a2ffe3f4a067eaf8f9f73",
  "627ea4876340e14cf7081778",
  "645e45785d7fb40bdb35ad96",
  "649aa43b584a5b0bd031f7fd",
];

export const RAZORPAY_CLIENT_ID =
  process.env.REACT_APP_RAZORPAY_CLIENT_ID ||
  (process.env.REACT_APP_MODE === "production"
    ? "HbGzHzds4AuWRL"
    : "HbGzHU3g3aZG07");

export const appURL =
  process.env.REACT_APP_INTEGRATIONS_SERVER_URL ||
  (process.env.REACT_APP_MODE === "production"
    ? "https://backend.aisensy.com/apps"
    : process.env.REACT_APP_MODE === "staging"
    ? "https://backend.aisensy.com/stg/apps"
    : process.env.REACT_APP_MODE === "prestaging"
    ? "https://backend.aisensy.org/apps"
    : "http://localhost:5006/apps");

export const baseURL =
  process.env.REACT_APP_MODE === "production"
    ? "https://www.partner.aisensy.com"
    : process.env.REACT_APP_MODE === "staging"
    ? "https://staging.d11r2ku2yg5e7.amplifyapp.com"
    : "http://localhost:3000";
